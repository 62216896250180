//
// Helpers
//
function $(selector, context) {
	return (context || document).querySelector(selector);
}

function $All(selector, context) {
	var arr = [];
	arr.push.apply(arr, (context || document).querySelectorAll(selector));
	return arr;
}

function tabletInStorage() {
	return localStorage.getItem("tablet");
}

//
// Intersection Observer Polyfill
//
(function () {
	"use strict";

	// Exit early if we're not running in a browser.
	if (typeof window !== "object") {
		return;
	}

	// Exit early if all IntersectionObserver and IntersectionObserverEntry
	// features are natively supported.
	if (
		"IntersectionObserver" in window &&
		"IntersectionObserverEntry" in window &&
		"intersectionRatio" in window.IntersectionObserverEntry.prototype
	) {
		// Minimal polyfill for Edge 15's lack of `isIntersecting`
		// See: https://github.com/w3c/IntersectionObserver/issues/211
		if (!("isIntersecting" in window.IntersectionObserverEntry.prototype)) {
			Object.defineProperty(
				window.IntersectionObserverEntry.prototype,
				"isIntersecting",
				{
					get: function () {
						return this.intersectionRatio > 0;
					},
				}
			);
		}
		return;
	}

	/**
	 * Returns the embedding frame element, if any.
	 * @param {!Document} doc
	 * @return {!Element}
	 */
	function getFrameElement(doc) {
		try {
			return (doc.defaultView && doc.defaultView.frameElement) || null;
		} catch (e) {
			// Ignore the error.
			return null;
		}
	}

	/**
	 * A local reference to the root document.
	 */
	var document = (function (startDoc) {
		var doc = startDoc;
		var frame = getFrameElement(doc);
		while (frame) {
			doc = frame.ownerDocument;
			frame = getFrameElement(doc);
		}
		return doc;
	})(window.document);

	/**
	 * An IntersectionObserver registry. This registry exists to hold a strong
	 * reference to IntersectionObserver instances currently observing a target
	 * element. Without this registry, instances without another reference may be
	 * garbage collected.
	 */
	var registry = [];

	/**
	 * The signal updater for cross-origin intersection. When not null, it means
	 * that the polyfill is configured to work in a cross-origin mode.
	 * @type {function(DOMRect|ClientRect, DOMRect|ClientRect)}
	 */
	var crossOriginUpdater = null;

	/**
	 * The current cross-origin intersection. Only used in the cross-origin mode.
	 * @type {DOMRect|ClientRect}
	 */
	var crossOriginRect = null;

	/**
	 * Creates the global IntersectionObserverEntry constructor.
	 * https://w3c.github.io/IntersectionObserver/#intersection-observer-entry
	 * @param {Object} entry A dictionary of instance properties.
	 * @constructor
	 */
	function IntersectionObserverEntry(entry) {
		this.time = entry.time;
		this.target = entry.target;
		this.rootBounds = ensureDOMRect(entry.rootBounds);
		this.boundingClientRect = ensureDOMRect(entry.boundingClientRect);
		this.intersectionRect = ensureDOMRect(
			entry.intersectionRect || getEmptyRect()
		);
		this.isIntersecting = !!entry.intersectionRect;

		// Calculates the intersection ratio.
		var targetRect = this.boundingClientRect;
		var targetArea = targetRect.width * targetRect.height;
		var intersectionRect = this.intersectionRect;
		var intersectionArea = intersectionRect.width * intersectionRect.height;

		// Sets intersection ratio.
		if (targetArea) {
			// Round the intersection ratio to avoid floating point math issues:
			// https://github.com/w3c/IntersectionObserver/issues/324
			this.intersectionRatio = Number(
				(intersectionArea / targetArea).toFixed(4)
			);
		} else {
			// If area is zero and is intersecting, sets to 1, otherwise to 0
			this.intersectionRatio = this.isIntersecting ? 1 : 0;
		}
	}

	/**
	 * Creates the global IntersectionObserver constructor.
	 * https://w3c.github.io/IntersectionObserver/#intersection-observer-interface
	 * @param {Function} callback The function to be invoked after intersection
	 *     changes have queued. The function is not invoked if the queue has
	 *     been emptied by calling the `takeRecords` method.
	 * @param {Object=} opt_options Optional configuration options.
	 * @constructor
	 */
	function IntersectionObserver(callback, opt_options) {
		var options = opt_options || {};

		if (typeof callback != "function") {
			throw new Error("callback must be a function");
		}

		if (
			options.root &&
			options.root.nodeType != 1 &&
			options.root.nodeType != 9
		) {
			throw new Error("root must be a Document or Element");
		}

		// Binds and throttles `this._checkForIntersections`.
		this._checkForIntersections = throttle(
			this._checkForIntersections.bind(this),
			this.THROTTLE_TIMEOUT
		);

		// Private properties.
		this._callback = callback;
		this._observationTargets = [];
		this._queuedEntries = [];
		this._rootMarginValues = this._parseRootMargin(options.rootMargin);

		// Public properties.
		this.thresholds = this._initThresholds(options.threshold);
		this.root = options.root || null;
		this.rootMargin = this._rootMarginValues
			.map(function (margin) {
				return margin.value + margin.unit;
			})
			.join(" ");

		/** @private @const {!Array<!Document>} */
		this._monitoringDocuments = [];
		/** @private @const {!Array<function()>} */
		this._monitoringUnsubscribes = [];
	}

	/**
	 * The minimum interval within which the document will be checked for
	 * intersection changes.
	 */
	IntersectionObserver.prototype.THROTTLE_TIMEOUT = 100;

	/**
	 * The frequency in which the polyfill polls for intersection changes.
	 * this can be updated on a per instance basis and must be set prior to
	 * calling `observe` on the first target.
	 */
	IntersectionObserver.prototype.POLL_INTERVAL = null;

	/**
	 * Use a mutation observer on the root element
	 * to detect intersection changes.
	 */
	IntersectionObserver.prototype.USE_MUTATION_OBSERVER = true;

	/**
	 * Sets up the polyfill in the cross-origin mode. The result is the
	 * updater function that accepts two arguments: `boundingClientRect` and
	 * `intersectionRect` - just as these fields would be available to the
	 * parent via `IntersectionObserverEntry`. This function should be called
	 * each time the iframe receives intersection information from the parent
	 * window, e.g. via messaging.
	 * @return {function(DOMRect|ClientRect, DOMRect|ClientRect)}
	 */
	IntersectionObserver._setupCrossOriginUpdater = function () {
		if (!crossOriginUpdater) {
			/**
			 * @param {DOMRect|ClientRect} boundingClientRect
			 * @param {DOMRect|ClientRect} intersectionRect
			 */
			crossOriginUpdater = function (
				boundingClientRect,
				intersectionRect
			) {
				if (!boundingClientRect || !intersectionRect) {
					crossOriginRect = getEmptyRect();
				} else {
					crossOriginRect = convertFromParentRect(
						boundingClientRect,
						intersectionRect
					);
				}
				registry.forEach(function (observer) {
					observer._checkForIntersections();
				});
			};
		}
		return crossOriginUpdater;
	};

	/**
	 * Resets the cross-origin mode.
	 */
	IntersectionObserver._resetCrossOriginUpdater = function () {
		crossOriginUpdater = null;
		crossOriginRect = null;
	};

	/**
	 * Starts observing a target element for intersection changes based on
	 * the thresholds values.
	 * @param {Element} target The DOM element to observe.
	 */
	IntersectionObserver.prototype.observe = function (target) {
		var isTargetAlreadyObserved = this._observationTargets.some(function (
			item
		) {
			return item.element == target;
		});

		if (isTargetAlreadyObserved) {
			return;
		}

		if (!(target && target.nodeType == 1)) {
			throw new Error("target must be an Element");
		}

		this._registerInstance();
		this._observationTargets.push({ element: target, entry: null });
		this._monitorIntersections(target.ownerDocument);
		this._checkForIntersections();
	};

	/**
	 * Stops observing a target element for intersection changes.
	 * @param {Element} target The DOM element to observe.
	 */
	IntersectionObserver.prototype.unobserve = function (target) {
		this._observationTargets = this._observationTargets.filter(function (
			item
		) {
			return item.element != target;
		});
		this._unmonitorIntersections(target.ownerDocument);
		if (this._observationTargets.length == 0) {
			this._unregisterInstance();
		}
	};

	/**
	 * Stops observing all target elements for intersection changes.
	 */
	IntersectionObserver.prototype.disconnect = function () {
		this._observationTargets = [];
		this._unmonitorAllIntersections();
		this._unregisterInstance();
	};

	/**
	 * Returns any queue entries that have not yet been reported to the
	 * callback and clears the queue. This can be used in conjunction with the
	 * callback to obtain the absolute most up-to-date intersection information.
	 * @return {Array} The currently queued entries.
	 */
	IntersectionObserver.prototype.takeRecords = function () {
		var records = this._queuedEntries.slice();
		this._queuedEntries = [];
		return records;
	};

	/**
	 * Accepts the threshold value from the user configuration object and
	 * returns a sorted array of unique threshold values. If a value is not
	 * between 0 and 1 and error is thrown.
	 * @private
	 * @param {Array|number=} opt_threshold An optional threshold value or
	 *     a list of threshold values, defaulting to [0].
	 * @return {Array} A sorted list of unique and valid threshold values.
	 */
	IntersectionObserver.prototype._initThresholds = function (opt_threshold) {
		var threshold = opt_threshold || [0];
		if (!Array.isArray(threshold)) threshold = [threshold];

		return threshold.sort().filter(function (t, i, a) {
			if (typeof t != "number" || isNaN(t) || t < 0 || t > 1) {
				throw new Error(
					"threshold must be a number between 0 and 1 inclusively"
				);
			}
			return t !== a[i - 1];
		});
	};

	/**
	 * Accepts the rootMargin value from the user configuration object
	 * and returns an array of the four margin values as an object containing
	 * the value and unit properties. If any of the values are not properly
	 * formatted or use a unit other than px or %, and error is thrown.
	 * @private
	 * @param {string=} opt_rootMargin An optional rootMargin value,
	 *     defaulting to '0px'.
	 * @return {Array<Object>} An array of margin objects with the keys
	 *     value and unit.
	 */
	IntersectionObserver.prototype._parseRootMargin = function (
		opt_rootMargin
	) {
		var marginString = opt_rootMargin || "0px";
		var margins = marginString.split(/\s+/).map(function (margin) {
			var parts = /^(-?\d*\.?\d+)(px|%)$/.exec(margin);
			if (!parts) {
				throw new Error(
					"rootMargin must be specified in pixels or percent"
				);
			}
			return { value: parseFloat(parts[1]), unit: parts[2] };
		});

		// Handles shorthand.
		margins[1] = margins[1] || margins[0];
		margins[2] = margins[2] || margins[0];
		margins[3] = margins[3] || margins[1];

		return margins;
	};

	/**
	 * Starts polling for intersection changes if the polling is not already
	 * happening, and if the page's visibility state is visible.
	 * @param {!Document} doc
	 * @private
	 */
	IntersectionObserver.prototype._monitorIntersections = function (doc) {
		var win = doc.defaultView;
		if (!win) {
			// Already destroyed.
			return;
		}
		if (this._monitoringDocuments.indexOf(doc) != -1) {
			// Already monitoring.
			return;
		}

		// Private state for monitoring.
		var callback = this._checkForIntersections;
		var monitoringInterval = null;
		var domObserver = null;

		// If a poll interval is set, use polling instead of listening to
		// resize and scroll events or DOM mutations.
		if (this.POLL_INTERVAL) {
			monitoringInterval = win.setInterval(callback, this.POLL_INTERVAL);
		} else {
			addEvent(win, "resize", callback, true);
			addEvent(doc, "scroll", callback, true);
			if (this.USE_MUTATION_OBSERVER && "MutationObserver" in win) {
				domObserver = new win.MutationObserver(callback);
				domObserver.observe(doc, {
					attributes: true,
					childList: true,
					characterData: true,
					subtree: true,
				});
			}
		}

		this._monitoringDocuments.push(doc);
		this._monitoringUnsubscribes.push(function () {
			// Get the window object again. When a friendly iframe is destroyed, it
			// will be null.
			var win = doc.defaultView;

			if (win) {
				if (monitoringInterval) {
					win.clearInterval(monitoringInterval);
				}
				removeEvent(win, "resize", callback, true);
			}

			removeEvent(doc, "scroll", callback, true);
			if (domObserver) {
				domObserver.disconnect();
			}
		});

		// Also monitor the parent.
		var rootDoc =
			(this.root && (this.root.ownerDocument || this.root)) || document;
		if (doc != rootDoc) {
			var frame = getFrameElement(doc);
			if (frame) {
				this._monitorIntersections(frame.ownerDocument);
			}
		}
	};

	/**
	 * Stops polling for intersection changes.
	 * @param {!Document} doc
	 * @private
	 */
	IntersectionObserver.prototype._unmonitorIntersections = function (doc) {
		var index = this._monitoringDocuments.indexOf(doc);
		if (index == -1) {
			return;
		}

		var rootDoc =
			(this.root && (this.root.ownerDocument || this.root)) || document;

		// Check if any dependent targets are still remaining.
		var hasDependentTargets = this._observationTargets.some(function (
			item
		) {
			var itemDoc = item.element.ownerDocument;
			// Target is in this context.
			if (itemDoc == doc) {
				return true;
			}
			// Target is nested in this context.
			while (itemDoc && itemDoc != rootDoc) {
				var frame = getFrameElement(itemDoc);
				itemDoc = frame && frame.ownerDocument;
				if (itemDoc == doc) {
					return true;
				}
			}
			return false;
		});
		if (hasDependentTargets) {
			return;
		}

		// Unsubscribe.
		var unsubscribe = this._monitoringUnsubscribes[index];
		this._monitoringDocuments.splice(index, 1);
		this._monitoringUnsubscribes.splice(index, 1);
		unsubscribe();

		// Also unmonitor the parent.
		if (doc != rootDoc) {
			var frame = getFrameElement(doc);
			if (frame) {
				this._unmonitorIntersections(frame.ownerDocument);
			}
		}
	};

	/**
	 * Stops polling for intersection changes.
	 * @param {!Document} doc
	 * @private
	 */
	IntersectionObserver.prototype._unmonitorAllIntersections = function () {
		var unsubscribes = this._monitoringUnsubscribes.slice(0);
		this._monitoringDocuments.length = 0;
		this._monitoringUnsubscribes.length = 0;
		for (var i = 0; i < unsubscribes.length; i++) {
			unsubscribes[i]();
		}
	};

	/**
	 * Scans each observation target for intersection changes and adds them
	 * to the internal entries queue. If new entries are found, it
	 * schedules the callback to be invoked.
	 * @private
	 */
	IntersectionObserver.prototype._checkForIntersections = function () {
		if (!this.root && crossOriginUpdater && !crossOriginRect) {
			// Cross origin monitoring, but no initial data available yet.
			return;
		}

		var rootIsInDom = this._rootIsInDom();
		var rootRect = rootIsInDom ? this._getRootRect() : getEmptyRect();

		this._observationTargets.forEach(function (item) {
			var target = item.element;
			var targetRect = getBoundingClientRect(target);
			var rootContainsTarget = this._rootContainsTarget(target);
			var oldEntry = item.entry;
			var intersectionRect =
				rootIsInDom &&
				rootContainsTarget &&
				this._computeTargetAndRootIntersection(
					target,
					targetRect,
					rootRect
				);

			var rootBounds = null;
			if (!this._rootContainsTarget(target)) {
				rootBounds = getEmptyRect();
			} else if (!crossOriginUpdater || this.root) {
				rootBounds = rootRect;
			}

			var newEntry = (item.entry = new IntersectionObserverEntry({
				time: now(),
				target: target,
				boundingClientRect: targetRect,
				rootBounds: rootBounds,
				intersectionRect: intersectionRect,
			}));

			if (!oldEntry) {
				this._queuedEntries.push(newEntry);
			} else if (rootIsInDom && rootContainsTarget) {
				// If the new entry intersection ratio has crossed any of the
				// thresholds, add a new entry.
				if (this._hasCrossedThreshold(oldEntry, newEntry)) {
					this._queuedEntries.push(newEntry);
				}
			} else {
				// If the root is not in the DOM or target is not contained within
				// root but the previous entry for this target had an intersection,
				// add a new record indicating removal.
				if (oldEntry && oldEntry.isIntersecting) {
					this._queuedEntries.push(newEntry);
				}
			}
		}, this);

		if (this._queuedEntries.length) {
			this._callback(this.takeRecords(), this);
		}
	};

	/**
	 * Accepts a target and root rect computes the intersection between then
	 * following the algorithm in the spec.
	 * TODO(philipwalton): at this time clip-path is not considered.
	 * https://w3c.github.io/IntersectionObserver/#calculate-intersection-rect-algo
	 * @param {Element} target The target DOM element
	 * @param {Object} targetRect The bounding rect of the target.
	 * @param {Object} rootRect The bounding rect of the root after being
	 *     expanded by the rootMargin value.
	 * @return {?Object} The final intersection rect object or undefined if no
	 *     intersection is found.
	 * @private
	 */
	IntersectionObserver.prototype._computeTargetAndRootIntersection =
		function (target, targetRect, rootRect) {
			// If the element isn't displayed, an intersection can't happen.
			if (window.getComputedStyle(target).display == "none") return;

			var intersectionRect = targetRect;
			var parent = getParentNode(target);
			var atRoot = false;

			while (!atRoot && parent) {
				var parentRect = null;
				var parentComputedStyle =
					parent.nodeType == 1 ? window.getComputedStyle(parent) : {};

				// If the parent isn't displayed, an intersection can't happen.
				if (parentComputedStyle.display == "none") return null;

				if (
					parent == this.root ||
					parent.nodeType == /* DOCUMENT */ 9
				) {
					atRoot = true;
					if (parent == this.root || parent == document) {
						if (crossOriginUpdater && !this.root) {
							if (
								!crossOriginRect ||
								(crossOriginRect.width == 0 &&
									crossOriginRect.height == 0)
							) {
								// A 0-size cross-origin intersection means no-intersection.
								parent = null;
								parentRect = null;
								intersectionRect = null;
							} else {
								parentRect = crossOriginRect;
							}
						} else {
							parentRect = rootRect;
						}
					} else {
						// Check if there's a frame that can be navigated to.
						var frame = getParentNode(parent);
						var frameRect = frame && getBoundingClientRect(frame);
						var frameIntersect =
							frame &&
							this._computeTargetAndRootIntersection(
								frame,
								frameRect,
								rootRect
							);
						if (frameRect && frameIntersect) {
							parent = frame;
							parentRect = convertFromParentRect(
								frameRect,
								frameIntersect
							);
						} else {
							parent = null;
							intersectionRect = null;
						}
					}
				} else {
					// If the element has a non-visible overflow, and it's not the <body>
					// or <html> element, update the intersection rect.
					// Note: <body> and <html> cannot be clipped to a rect that's not also
					// the document rect, so no need to compute a new intersection.
					var doc = parent.ownerDocument;
					if (
						parent != doc.body &&
						parent != doc.documentElement &&
						parentComputedStyle.overflow != "visible"
					) {
						parentRect = getBoundingClientRect(parent);
					}
				}

				// If either of the above conditionals set a new parentRect,
				// calculate new intersection data.
				if (parentRect) {
					intersectionRect = computeRectIntersection(
						parentRect,
						intersectionRect
					);
				}
				if (!intersectionRect) break;
				parent = parent && getParentNode(parent);
			}
			return intersectionRect;
		};

	/**
	 * Returns the root rect after being expanded by the rootMargin value.
	 * @return {ClientRect} The expanded root rect.
	 * @private
	 */
	IntersectionObserver.prototype._getRootRect = function () {
		var rootRect;
		if (this.root && !isDoc(this.root)) {
			rootRect = getBoundingClientRect(this.root);
		} else {
			// Use <html>/<body> instead of window since scroll bars affect size.
			var doc = isDoc(this.root) ? this.root : document;
			var html = doc.documentElement;
			var body = doc.body;
			rootRect = {
				top: 0,
				left: 0,
				right: html.clientWidth || body.clientWidth,
				width: html.clientWidth || body.clientWidth,
				bottom: html.clientHeight || body.clientHeight,
				height: html.clientHeight || body.clientHeight,
			};
		}
		return this._expandRectByRootMargin(rootRect);
	};

	/**
	 * Accepts a rect and expands it by the rootMargin value.
	 * @param {DOMRect|ClientRect} rect The rect object to expand.
	 * @return {ClientRect} The expanded rect.
	 * @private
	 */
	IntersectionObserver.prototype._expandRectByRootMargin = function (rect) {
		var margins = this._rootMarginValues.map(function (margin, i) {
			return margin.unit == "px"
				? margin.value
				: (margin.value * (i % 2 ? rect.width : rect.height)) / 100;
		});
		var newRect = {
			top: rect.top - margins[0],
			right: rect.right + margins[1],
			bottom: rect.bottom + margins[2],
			left: rect.left - margins[3],
		};
		newRect.width = newRect.right - newRect.left;
		newRect.height = newRect.bottom - newRect.top;

		return newRect;
	};

	/**
	 * Accepts an old and new entry and returns true if at least one of the
	 * threshold values has been crossed.
	 * @param {?IntersectionObserverEntry} oldEntry The previous entry for a
	 *    particular target element or null if no previous entry exists.
	 * @param {IntersectionObserverEntry} newEntry The current entry for a
	 *    particular target element.
	 * @return {boolean} Returns true if a any threshold has been crossed.
	 * @private
	 */
	IntersectionObserver.prototype._hasCrossedThreshold = function (
		oldEntry,
		newEntry
	) {
		// To make comparing easier, an entry that has a ratio of 0
		// but does not actually intersect is given a value of -1
		var oldRatio =
			oldEntry && oldEntry.isIntersecting
				? oldEntry.intersectionRatio || 0
				: -1;
		var newRatio = newEntry.isIntersecting
			? newEntry.intersectionRatio || 0
			: -1;

		// Ignore unchanged ratios
		if (oldRatio === newRatio) return;

		for (var i = 0; i < this.thresholds.length; i++) {
			var threshold = this.thresholds[i];

			// Return true if an entry matches a threshold or if the new ratio
			// and the old ratio are on the opposite sides of a threshold.
			if (
				threshold == oldRatio ||
				threshold == newRatio ||
				threshold < oldRatio !== threshold < newRatio
			) {
				return true;
			}
		}
	};

	/**
	 * Returns whether or not the root element is an element and is in the DOM.
	 * @return {boolean} True if the root element is an element and is in the DOM.
	 * @private
	 */
	IntersectionObserver.prototype._rootIsInDom = function () {
		return !this.root || containsDeep(document, this.root);
	};

	/**
	 * Returns whether or not the target element is a child of root.
	 * @param {Element} target The target element to check.
	 * @return {boolean} True if the target element is a child of root.
	 * @private
	 */
	IntersectionObserver.prototype._rootContainsTarget = function (target) {
		var rootDoc =
			(this.root && (this.root.ownerDocument || this.root)) || document;
		return (
			containsDeep(rootDoc, target) &&
			(!this.root || rootDoc == target.ownerDocument)
		);
	};

	/**
	 * Adds the instance to the global IntersectionObserver registry if it isn't
	 * already present.
	 * @private
	 */
	IntersectionObserver.prototype._registerInstance = function () {
		if (registry.indexOf(this) < 0) {
			registry.push(this);
		}
	};

	/**
	 * Removes the instance from the global IntersectionObserver registry.
	 * @private
	 */
	IntersectionObserver.prototype._unregisterInstance = function () {
		var index = registry.indexOf(this);
		if (index != -1) registry.splice(index, 1);
	};

	/**
	 * Returns the result of the performance.now() method or null in browsers
	 * that don't support the API.
	 * @return {number} The elapsed time since the page was requested.
	 */
	function now() {
		return window.performance && performance.now && performance.now();
	}

	/**
	 * Throttles a function and delays its execution, so it's only called at most
	 * once within a given time period.
	 * @param {Function} fn The function to throttle.
	 * @param {number} timeout The amount of time that must pass before the
	 *     function can be called again.
	 * @return {Function} The throttled function.
	 */
	function throttle(fn, timeout) {
		var timer = null;
		return function () {
			if (!timer) {
				timer = setTimeout(function () {
					fn();
					timer = null;
				}, timeout);
			}
		};
	}

	/**
	 * Adds an event handler to a DOM node ensuring cross-browser compatibility.
	 * @param {Node} node The DOM node to add the event handler to.
	 * @param {string} event The event name.
	 * @param {Function} fn The event handler to add.
	 * @param {boolean} opt_useCapture Optionally adds the even to the capture
	 *     phase. Note: this only works in modern browsers.
	 */
	function addEvent(node, event, fn, opt_useCapture) {
		if (typeof node.addEventListener == "function") {
			node.addEventListener(event, fn, opt_useCapture || false);
		} else if (typeof node.attachEvent == "function") {
			node.attachEvent("on" + event, fn);
		}
	}

	/**
	 * Removes a previously added event handler from a DOM node.
	 * @param {Node} node The DOM node to remove the event handler from.
	 * @param {string} event The event name.
	 * @param {Function} fn The event handler to remove.
	 * @param {boolean} opt_useCapture If the event handler was added with this
	 *     flag set to true, it should be set to true here in order to remove it.
	 */
	function removeEvent(node, event, fn, opt_useCapture) {
		if (typeof node.removeEventListener == "function") {
			node.removeEventListener(event, fn, opt_useCapture || false);
		} else if (typeof node.detatchEvent == "function") {
			node.detatchEvent("on" + event, fn);
		}
	}

	/**
	 * Returns the intersection between two rect objects.
	 * @param {Object} rect1 The first rect.
	 * @param {Object} rect2 The second rect.
	 * @return {?Object|?ClientRect} The intersection rect or undefined if no
	 *     intersection is found.
	 */
	function computeRectIntersection(rect1, rect2) {
		var top = Math.max(rect1.top, rect2.top);
		var bottom = Math.min(rect1.bottom, rect2.bottom);
		var left = Math.max(rect1.left, rect2.left);
		var right = Math.min(rect1.right, rect2.right);
		var width = right - left;
		var height = bottom - top;

		return (
			(width >= 0 &&
				height >= 0 && {
					top: top,
					bottom: bottom,
					left: left,
					right: right,
					width: width,
					height: height,
				}) ||
			null
		);
	}

	/**
	 * Shims the native getBoundingClientRect for compatibility with older IE.
	 * @param {Element} el The element whose bounding rect to get.
	 * @return {DOMRect|ClientRect} The (possibly shimmed) rect of the element.
	 */
	function getBoundingClientRect(el) {
		var rect;

		try {
			rect = el.getBoundingClientRect();
		} catch (err) {
			// Ignore Windows 7 IE11 "Unspecified error"
			// https://github.com/w3c/IntersectionObserver/pull/205
		}

		if (!rect) return getEmptyRect();

		// Older IE
		if (!(rect.width && rect.height)) {
			rect = {
				top: rect.top,
				right: rect.right,
				bottom: rect.bottom,
				left: rect.left,
				width: rect.right - rect.left,
				height: rect.bottom - rect.top,
			};
		}
		return rect;
	}

	/**
	 * Returns an empty rect object. An empty rect is returned when an element
	 * is not in the DOM.
	 * @return {ClientRect} The empty rect.
	 */
	function getEmptyRect() {
		return {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			width: 0,
			height: 0,
		};
	}

	/**
	 * Ensure that the result has all of the necessary fields of the DOMRect.
	 * Specifically this ensures that `x` and `y` fields are set.
	 *
	 * @param {?DOMRect|?ClientRect} rect
	 * @return {?DOMRect}
	 */
	function ensureDOMRect(rect) {
		// A `DOMRect` object has `x` and `y` fields.
		if (!rect || "x" in rect) {
			return rect;
		}
		// A IE's `ClientRect` type does not have `x` and `y`. The same is the case
		// for internally calculated Rect objects. For the purposes of
		// `IntersectionObserver`, it's sufficient to simply mirror `left` and `top`
		// for these fields.
		return {
			top: rect.top,
			y: rect.top,
			bottom: rect.bottom,
			left: rect.left,
			x: rect.left,
			right: rect.right,
			width: rect.width,
			height: rect.height,
		};
	}

	/**
	 * Inverts the intersection and bounding rect from the parent (frame) BCR to
	 * the local BCR space.
	 * @param {DOMRect|ClientRect} parentBoundingRect The parent's bound client rect.
	 * @param {DOMRect|ClientRect} parentIntersectionRect The parent's own intersection rect.
	 * @return {ClientRect} The local root bounding rect for the parent's children.
	 */
	function convertFromParentRect(parentBoundingRect, parentIntersectionRect) {
		var top = parentIntersectionRect.top - parentBoundingRect.top;
		var left = parentIntersectionRect.left - parentBoundingRect.left;
		return {
			top: top,
			left: left,
			height: parentIntersectionRect.height,
			width: parentIntersectionRect.width,
			bottom: top + parentIntersectionRect.height,
			right: left + parentIntersectionRect.width,
		};
	}

	/**
	 * Checks to see if a parent element contains a child element (including inside
	 * shadow DOM).
	 * @param {Node} parent The parent element.
	 * @param {Node} child The child element.
	 * @return {boolean} True if the parent node contains the child node.
	 */
	function containsDeep(parent, child) {
		var node = child;
		while (node) {
			if (node == parent) return true;

			node = getParentNode(node);
		}
		return false;
	}

	/**
	 * Gets the parent node of an element or its host element if the parent node
	 * is a shadow root.
	 * @param {Node} node The node whose parent to get.
	 * @return {Node|null} The parent node or null if no parent exists.
	 */
	function getParentNode(node) {
		var parent = node.parentNode;

		if (node.nodeType == /* DOCUMENT */ 9 && node != document) {
			// If this node is a document node, look for the embedding frame.
			return getFrameElement(node);
		}

		// If the parent has element that is assigned through shadow root slot
		if (parent && parent.assignedSlot) {
			parent = parent.assignedSlot.parentNode;
		}

		if (parent && parent.nodeType == 11 && parent.host) {
			// If the parent is a shadow root, return the host element.
			return parent.host;
		}

		return parent;
	}

	/**
	 * Returns true if `node` is a Document.
	 * @param {!Node} node
	 * @returns {boolean}
	 */
	function isDoc(node) {
		return node && node.nodeType === 9;
	}

	// Exposes the constructors globally.
	window.IntersectionObserver = IntersectionObserver;
	window.IntersectionObserverEntry = IntersectionObserverEntry;
})();

//
// forEach Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
//
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

//
// Element.closest() Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
//
if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

//
// Element.remove() Polyfill
// https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
//
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty("remove")) {
			return;
		}
		Object.defineProperty(item, "remove", {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function remove() {
				if (this.parentNode !== null) this.parentNode.removeChild(this);
			},
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

//
// Get CSS Breakpoints Value
//
var breakpoint = {};
breakpoint.refreshValue = function () {
	this.value = Math.max(
		document.documentElement.clientWidth || 0,
		window.innerWidth || 0
	);
};
window.addEventListener("resize", function () {
	breakpoint.refreshValue();
});
breakpoint.refreshValue();

//
// IE detection
// returns version of IE or false, if browser is not Internet Explorer
//
function detectIE() {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf("MSIE ");
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
	}

	var trident = ua.indexOf("Trident/");
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf("rv:");
		return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
	}

	var edge = ua.indexOf("Edge/");
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
	}

	// other browser
	return false;
}

if (detectIE() != false) {
	if (detectIE() == 16) {
		document.documentElement.classList.add("msie edge-16");
	} else if (detectIE() >= 17) {
		document.documentElement.classList.add("no-msie edge-17+");
	} else {
		document.documentElement.classList.add("msie");
	}
} else {
	document.documentElement.classList.add("no-msie");
}

//
// Remove .no-js class from html element if javascript is enabled
//
document.documentElement.className = document.documentElement.className.replace(
	"no-js",
	"js"
);

//
// Test for touchevent support and if not supported, attach .no-touch class to the HTML tag.
// https://www.prowebdesign.ro/how-to-deal-with-hover-on-touch-screen-devices/
//
if (!("ontouchstart" in document.documentElement)) {
	document.documentElement.className += " no-touch";
}

var hash = window.location.hash;
let body = document.body;

let toTopLink = document.querySelector(".js-totop");
let animationItems = $All(
	".animate .textmedia__media, .animate.section--has-image, .animate.section--has-imageportrait"
);

//
// To-Top Anchor Link
//
if (toTopLink) {
	let pixeltowatch = $(".pixeltowatch");

	if ("IntersectionObserver" in window) {
		let config = {
			root: null,
			rootMargin: "0px",
			threshold: 0,
		};

		let observer = new IntersectionObserver(onChange, config);
		observer.observe(pixeltowatch);

		function onChange(changes, observer) {
			changes.forEach((change) => {
				if (change.isIntersecting) {
					toTopLink.classList.add("is--visible");
				} else {
					if (change.boundingClientRect.top > 0) {
						toTopLink.classList.remove("is--visible");
					}
				}
			});
		}
	} else {
		console.log("No Intersection Observer Support");
		toTopLink.classList.add("intersectionObserverNotSupported");
	}
}

//
// Accordion
//
var accordion = $All(".js-accordion");
var accordionButton = $All(".js-accordion__button");
var accordionCollapse = $All(".js-accordion__collapse");

function toggleAccordionItem(el) {
	var isopen = el.getAttribute("aria-expanded");
	var parent = el.parentNode.parentNode;
	var title = parent.querySelector(".accordion__title, .timeline__title");
	var content = parent.querySelector(
		".accordion__collapse, .timeline__collapse"
	);

	if (isopen == "false") {
		el.setAttribute("aria-expanded", true);
		parent.classList.add("is--open");
		title.classList.add("is--open");
		el.blur();

		content.style.height = "auto";
		var height = content.clientHeight + "px";
		content.style.height = "0px";

		setTimeout(function () {
			content.style.height = height;
			setTimelineImages(parent);

			content.addEventListener(
				"transitionend",
				function () {
					if (parent.querySelector(".js-timeline__media") != null) {
						parent.querySelector(
							".js-timeline__media .image"
						).style.opacity = "1";
					}
				},
				{
					once: true,
				}
			);
		}, 34);
	} else {
		if (parent.querySelector(".js-timeline__media") != null) {
			parent.querySelector(".js-timeline__media .image").style.opacity =
				"0";

			content.style.height = "0px";
			parent.parentNode.style.minHeight = "0";

			setTimeout(function () {
				el.setAttribute("aria-expanded", false);
				el.blur();
				parent.classList.remove("is--open");
				title.classList.remove("is--open");
			}, 500);
		} else {
			content.style.height = "0px";
			content.addEventListener(
				"transitionend",
				function () {
					el.setAttribute("aria-expanded", false);
					el.blur();
					parent.classList.remove("is--open");
					title.classList.remove("is--open");
				},
				{
					once: true,
				}
			);
		}
	}
}

if (accordion.length > 0) {
	accordionButton.forEach(function (el) {
		el.addEventListener("click", function (e) {
			e.preventDefault();
			toggleAccordionItem(el);
		});
	});

	accordion.forEach(function (el) {
		if (el.classList.contains("is--openOnPageload")) {
			toggleAccordionItem(
				el.getElementsByClassName("js-accordion__button")[0]
			);
			el.classList.remove("is--openOnPageload");
		}
	});

	// check if hash has changed
	function locationHashChanged() {
		const el = document.getElementById(location.hash.substring(1));
		if (el && el.classList.contains("js-accordion__button")) {
			toggleAccordionItem(el);
		}
	}
	window.onhashchange = locationHashChanged;

	// check if uri has hash
	if (hash != "") {
		const el = document.getElementById(hash.substring(1));
		if (el && el.classList.contains("js-accordion__button")) {
			toggleAccordionItem(el);
		}
	}
}

//
// Carousel
//
var carousel = $All(".js-carousel");
var carouselCollapse = $All(".js-carousel__collapse");
var carouselMedia = $All(".js-carousel__media");

if (carousel.length > 0) {
	// Close all Elements at all Carousels
	carouselCollapse.forEach(function (el) {
		if (breakpoint.value >= 800) {
			el.setAttribute(
				"data-height",
				el.getBoundingClientRect().height + "px"
			);
		} else {
			el.setAttribute("data-height", "auto");
		}
		el.style.height = 0;
	});

	// Open first Element at each Carousel
	carousel.forEach(function (carouselElement) {
		var FirstElementHeight = carouselElement
			.getElementsByClassName("js-carousel__collapse")[0]
			.getAttribute("data-height");
		carouselElement.getElementsByClassName(
			"js-carousel__collapse"
		)[0].style.height = FirstElementHeight;
		carouselElement
			.getElementsByClassName("js-carousel__collapse")[0]
			.setAttribute("aria-expanded", true);
		carouselElement
			.getElementsByClassName("js-carousel__title")[0]
			.classList.add("is--open");
		carouselElement
			.getElementsByClassName("js-carousel__media")[0]
			.setAttribute("style", "opacity: 1; visibility: visible;");

		function openCarouselItem(el, i) {
			var parentList = el.parentNode.parentNode;
			var parentCarousel = el.parentNode.parentNode.parentNode.parentNode;
			var elementHeight;

			if (breakpoint.value >= 800) {
				elementHeight =
					el.nextElementSibling.getAttribute("data-height");
			} else {
				var elementMediaHeight = el.nextElementSibling
					.getElementsByClassName("carousel__media")[0]
					.getBoundingClientRect().height;
				var elementContentHeight = el.nextElementSibling
					.getElementsByClassName("carousel__content")[0]
					.getBoundingClientRect().height;
				elementHeight = elementMediaHeight + elementContentHeight;
			}

			parentList
				.querySelectorAll(".js-carousel__collapse")
				.forEach(function (el) {
					el.style.height = 0;
				});

			parentList
				.querySelectorAll(".js-carousel__title")
				.forEach(function (el) {
					el.classList.remove("is--open");
				});

			parentCarousel
				.querySelectorAll(".js-carousel__media")
				.forEach(function (el) {
					el.setAttribute("style", "opacity: 0; visibility: hidden;");
				});

			el.nextElementSibling.setAttribute("aria-expanded", true);
			el.nextElementSibling.setAttribute(
				"style",
				"height:" + elementHeight
			);
			el.classList.add("is--open");
			parentCarousel
				.getElementsByClassName("js-carousel__media")
				[i].setAttribute("style", "opacity: 1; visibility: visible;");
		}

		carouselElement
			.querySelectorAll(".js-carousel__title")
			.forEach(function (el, i) {
				el.addEventListener("click", function (e) {
					e.preventDefault();
					openCarouselItem(el, i);
					el.blur();
				});

				el.addEventListener("keypress", function (e) {
					var key = e.which || e.keyCode;
					if (key === 13) {
						openCarouselItem(el, i);
					}
				});
			});
	});
}

//
// Pause all playing Videos
//
function pauseAllVideos() {
	var video = $All("video");
	var youtube = $All(".js-video.is--playing");

	if (video.length > 0) {
		video.forEach(function (el) {
			el.pause();
		});
	}
}

//
// Special Videoplayer with Autostart Video
//
function playFullVideo(e) {
	pauseAllVideos();

	if (e.target.classList.contains("js-videobuttonwrapper")) {
		var videoteaser = e.target.previousElementSibling;
		var videofull = e.target.previousElementSibling.previousElementSibling;
		e.target.remove();
	} else if (e.target.classList.contains("js-videobutton")) {
		var videoteaser = e.target
			.closest(".js-videoplayerautostart")
			.querySelector(".js-videoteaser");
		var videofull = e.target
			.closest(".js-videoplayerautostart")
			.querySelector(".js-videofull");
		e.target.parentNode.remove();
	} else {
		console.log("Error playFullVideo.");
	}

	videoteaser.pause();
	videoteaser.removeAttribute("loop");
	videoteaser.removeAttribute("autoplay");
	videoteaser.classList.add("is--hidden");
	videofull.classList.remove("is--hidden");
	videofull.play();
}

var autostartVideoPlayer = $All(".js-videoplayerautostart");

if (autostartVideoPlayer.length > 0) {
	autostartVideoPlayer.forEach(function (video) {
		video
			.querySelector(".js-videobuttonwrapper")
			.addEventListener("click", function (event) {
				if (video.querySelector(".js-videofull") != null) {
					playFullVideo(event, true);
				} else if (video.querySelector(".js-externalvideo") != null) {
					pasteVideoPlayer(event, true);
				} else {
					console.log("Error autostartVideoPlayer");
				}
			});

		video
			.querySelector(".js-videobuttonwrapper")
			.addEventListener("keydown", function (event) {
				var key = event.which || event.keyCode;
				if (key === 13 || key === 32) {
					if (video.querySelector(".js-videofull") != null) {
						playFullVideo(event, true);
					} else if (
						video.querySelector(".js-externalvideo") != null
					) {
						pasteVideoPlayer(event, true);
					} else {
						console.log("Error autostartVideoPlayer");
					}
				}
			});
	});
}

window.dwmEmConsent =
	window.dwmEmConsent ||
	JSON.parse(localStorage.getItem("dwm-em-consent") || "{}");

//
// Paste Video Player (Youtube, Vimeo or own html5 video)
//
function pasteVideoPlayer(e, videoPlaying) {
	e.preventDefault();
	e.stopPropagation();
	var videoplayer;
	var cookieplaceholder;

	pauseAllVideos();

	if (
		videoPlaying === false &&
		e.target.classList.contains("js-videobuttonwrapper")
	) {
		// console.log('js-videobuttonwrapper click');
		videoplayer = e.target.closest(".js-video");
		cookieplaceholder = videoplayer.querySelector(".js-cookieplaceholder");
	} else if (
		videoPlaying === false &&
		e.target.classList.contains("js-videobutton")
	) {
		// console.log('js-videobutton click');
		videoplayer = e.target.closest(".js-video");
		cookieplaceholder = videoplayer.querySelector(".js-cookieplaceholder");
	} else if (
		videoPlaying === true &&
		(e.target.classList.contains("js-videobuttonwrapper") ||
			e.target.classList.contains("js-videobutton"))
	) {
		// console.log('js-videobuttonwrapper or js-videobutton click @ Autoplay-Teaser');
		var videoContainer = e.target.closest(".js-videoplayerautostart");
		videoContainer.querySelector(".js-videoteaser").pause();
		videoContainer.querySelector(".js-videoteaser").removeAttribute("loop");
		videoContainer
			.querySelector(".js-videoteaser")
			.removeAttribute("autoplay");
		videoContainer
			.querySelector(".js-videoteaser")
			.classList.add("is--hidden");
		videoplayer = videoContainer.querySelector(".js-externalvideo");
		videoContainer.querySelector(".js-videobuttonwrapper").remove();
		cookieplaceholder = videoContainer.querySelector(
			".js-cookieplaceholder"
		);
	} else {
		console.log("Error pasting external Video.");
	}

	var ytID = videoplayer.getAttribute("data-youtube"),
		vimeoID = videoplayer.getAttribute("data-vimeo"),
		starttime = videoplayer.getAttribute("data-starttime") || 0,
		width = videoplayer.offsetWidth,
		height = videoplayer.offsetHeight,
		videoInner = videoplayer.querySelector(".video__inner"),
		src = false;

	if (ytID) {
		src =
			"https://www.youtube-nocookie.com/embed/" +
			ytID +
			"?rel=0&showinfo=0&autoplay=1&start=" +
			starttime;
	} else if (vimeoID) {
		src =
			"https://player.vimeo.com/video/" +
			vimeoID +
			"?autoplay=1&color=ff0179#t=" +
			starttime +
			"s";
	} else {
		// Special Version for own html5 Videoplayer
		var html5video = videoplayer.querySelector("video");
		if (html5video) {
			videoplayer.querySelector(".video__cover").remove();
			videoplayer.querySelector(".js-videobuttonwrapper").remove();
			html5video.play();
			html5video.setAttribute("tabindex", "0");
		} else {
			console.log("Error. Missing Video-ID.");
		}
	}

	if (src) {
		if (dwmEmConsent.externalMedia) {
			cookieplaceholder && cookieplaceholder.remove();
			videoInner.innerHTML =
				'<iframe class="video__iframe" width="' +
				width +
				'" height="' +
				height +
				'" src="' +
				src +
				'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
			videoplayer.classList.toggle("is--playing");
		} else {
			cookieplaceholder &&
				cookieplaceholder.classList.remove("is--hidden");
			document.addEventListener(
				"dwm-cookies",
				function (e) {
					if (e.detail.external) {
						dwmEmConsent.externalMedia = true;
						localStorage.setItem(
							"dwm-em-consent",
							JSON.stringify(dwmEmConsent)
						);
						cookieplaceholder && cookieplaceholder.remove();
						videoInner.innerHTML =
							'<iframe class="video__iframe" width="' +
							width +
							'" height="' +
							height +
							'" src="' +
							src +
							'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
						videoplayer.classList.toggle("is--playing");
					}
				},
				false
			);
		}
	}
}

var videoPlayer = $All(".js-video");

if (videoPlayer.length > 0) {
	videoPlayer.forEach(function (video) {
		video
			.querySelector(".js-videobuttonwrapper")
			.addEventListener("click", function (event) {
				pasteVideoPlayer(event, false);
			});

		video
			.querySelector(".js-videobuttonwrapper")
			.addEventListener("keydown", function (event) {
				var key = event.which || event.keyCode;
				if (key === 13 || key === 32) {
					pasteVideoPlayer(event, false);
				}
			});
	});
}

//
// Videoplayer Start at current Time on Link click
//
var videoPlayerStarttimeLink = $All('a[href*="#"][href*="&"]');

function startVideoAtTimecode(el) {
	const parts = el.href.split("&");
	const targetVideo = parts[0].split("#")[1];
	const targetTime = parts[1];
	const videoPlayerContainer = document.getElementById(targetVideo);
	console.log("Starte Video #" + targetVideo + " bei Sekunde " + targetTime);

	const videoplayer = videoPlayerContainer.querySelector(".js-video");
	const html5video = videoplayer.querySelector("video");

	if (html5video) {
		const buttonWrapper = videoPlayerContainer.querySelector(
			".js-videobuttonwrapper"
		);
		if (buttonWrapper !== null) {
			// Video wasnt started yet
			// console.log('Play Button ist noch da.');
			videoplayer.querySelector(".video__cover").remove();
			buttonWrapper.remove();
		} else {
			// Video was already playing
			// console.log('Video wurde schon gestartet');
		}
		html5video.currentTime = parseFloat(targetTime);
		videoplayer.scrollIntoView(true);
		html5video.play();
		html5video.setAttribute("tabindex", "0");
	}
}

if (videoPlayerStarttimeLink.length > 0) {
	videoPlayerStarttimeLink.forEach(function (link) {
		if (link.href.indexOf("#") < link.href.indexOf("&")) {
			link.addEventListener("click", function (event) {
				event.preventDefault();
				startVideoAtTimecode(link);
			});
		}
	});
}

//
// Intersection Observr for Animations
//
if (animationItems.length > 0) {
	if ("IntersectionObserver" in window) {
		let observer = new IntersectionObserver(
			(elements) => {
				let leftOffset = document
					.querySelector(".section")
					.getBoundingClientRect().left;
				let delayMultiplier = 500.0 / window.innerWidth;
				elements.forEach((element) => {
					if (element.intersectionRatio > 0) {
						let delay =
							delayMultiplier *
							(element.boundingClientRect.left - leftOffset);
						element.target.timeout = setTimeout(() => {
							element.target.classList.add("animate-in");
						}, delay);
					} else {
						if (element.target.classList.contains("animate")) {
							clearTimeout(element.target.timeout);
							element.target.classList.remove("animate-in");
						}
					}
				});
			},
			{
				rootMargin: "20px 0% 0% 0%",
				threshold: [0.3],
			}
		);

		animationItems.forEach((element) => {
			observer.observe(element);
		});
	} else {
		console.log("No Intersection Observer Support");
		animationItems.forEach((element) => {
			element.classList.add("animate-in");
		});
	}
}

//
// SVG Bugfix for IE 11
//
const svgItems = $All(".section .image__item svg");

function fixSvgSize(el) {
	const elAttrWidth = el.getAttribute("width");
	const elAttrHeight = el.getAttribute("height");
	const elAttrRation = elAttrHeight / elAttrWidth;
	const elWidth = el.getBoundingClientRect().width;
	const newHeight = Math.round(elWidth * elAttrRation) + "px";
	el.style.height = newHeight;
}

if (
	document.documentElement.classList.contains("msie") &&
	svgItems.length > 0
) {
	svgItems.forEach(function (el) {
		fixSvgSize(el);
	});

	window.addEventListener("resize", function () {
		svgItems.forEach(function (el) {
			fixSvgSize(el);
		});
	});
}

//
// Tooltip
//
const tooltips = $All('a[href="#"][title]');

if (tooltips.length > 0) {
	tooltips.forEach((item) => {
		item.addEventListener("click", function (event) {
			event.preventDefault();
		});
	});
}

//
// Change Cookie Settings
//
// https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
//
var cookieplaceholderSettingsButton = document.querySelectorAll(
	".js-cookieplaceholder__button"
);

if (cookieplaceholderSettingsButton != null) {
	cookieplaceholderSettingsButton.forEach(function (button) {
		button.addEventListener("click", function (event) {
			event.preventDefault();
			let existingStorageObject = localStorage.getItem("bannerStorage");
			existingStorageObject = existingStorageObject
				? JSON.parse(existingStorageObject)
				: {};
			existingStorageObject.external = true;
			localStorage.setItem(
				"bannerStorage",
				JSON.stringify(existingStorageObject)
			);
			dwmEmConsent.externalMedia = true;
			localStorage.setItem(
				"dwm-em-consent",
				JSON.stringify(dwmEmConsent)
			);

			function setCookies(all, statistic, external) {
				const cookieEvent = document.createEvent("CustomEvent");
				cookieEvent.initCustomEvent("dwm-cookies", false, false, {
					all: all || (statistic && external),
					statistic,
					external,
				});
				document.dispatchEvent(cookieEvent);
			}

			setCookies(
				existingStorageObject.all,
				existingStorageObject.statistic,
				true
			);
		});
	});
}

function injectIframes() {
	$All(".js-iframe").forEach(function (iframeWrapper) {
		var iframe = document.createElement("iframe");
		iframe.className = "iframe__iframe";
		iframe.src = iframeWrapper.getAttribute("data-src");
		iframeWrapper.replaceChild(iframe, iframeWrapper.firstElementChild);
	});
}

if (window.dwmCb && window.dwmCb.external) {
	injectIframes();
} else {
	document.addEventListener(
		"dwm-cookies",
		function (e) {
			if (e.detail.external) {
				injectIframes();
			}
		},
		false
	);
}

//
// Set active state of menu links
//
const currentHref =
	location.protocol + "//" + location.host + location.pathname;
$All(".header a, .footer a").forEach((link) => {
	if (link.href === currentHref) {
		link.classList.add("is-active");
	}
});

//
// Throttle
//
function throttle(func, wait, options) {
	var context, args, result;
	var timeout = null;
	var previous = 0;
	if (!options) options = {};
	var later = function () {
		previous = options.leading === false ? 0 : Date.now();
		timeout = null;
		result = func.apply(context, args);
		if (!timeout) context = args = null;
	};
	return function () {
		var now = Date.now();
		if (!previous && options.leading === false) previous = now;
		var remaining = wait - (now - previous);
		context = this;
		args = arguments;
		if (remaining <= 0 || remaining > wait) {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
			previous = now;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
		}
		return result;
	};
}

//
// DB Cookie Content Layer button in website footer
// https://consentlayer.bahn-x.de/quickstart#2-add-a-manage-consent-button-to-websites-footer
//
const cookieConsentMangerToogleButton = document.querySelectorAll(
	".dwm-cb-open-cookie-banner"
);
cookieConsentMangerToogleButton.forEach(function (button) {
	button.addEventListener("click", function (event) {
		event.preventDefault();
		window.DB_CMP.showSecondLayer();
	});
});

function createEl(el, classes = null, text = null, hidden = false) {
	const element = document.createElement(el);

	if (classes != null) {
		if (Array.isArray(classes)) {
			classes.forEach((c) => {
				element.classList.add(c);
			});
		} else element.classList.add(classes);
	}

	if (text != null) element.textContent = text;
	if (hidden == true) element.setAttribute("hidden", true);

	return element;
}

/**
 * Filter will be init from inside the Blog class
 */
class Filter {
	/**
	 * [constructor description]
	 *
	 * @param   {node}  el    filter element node
	 * @param   {object}  blog  Blog instance
	 *
	 * @return  {object}        new filter instance
	 */
	constructor(el, blog) {
		this.s = {
			wrapper: ".js-filter__wrapper",
			checkbox: ".filter__checkbox",
			checkboxAll: ".filter__checkbox--all",
			toggle: ".js-filter__toggle",
			toggleText: ".js-filter__toggle-text",
			filterButton: "label .filter__button",
		};

		this.e = {
			filter: el,
			wrapper: el.querySelector(this.s.wrapper),
			filterOptions: el.querySelectorAll(this.s.checkbox),
			filterOptionAll: el.querySelector(this.s.checkboxAll),
			toggle: el.querySelector(this.s.toggle),
			toggleText: el.querySelector(this.s.toggleText),
		};

		this.options = {
			buttonActiveClass: "filter__button--active",
			expandedClass: "filter--expanded", // for mobile
			collapseBreakpoint: 800,
		};

		this.state = {
			selectedFilters: [this.e.filterOptions[0].value], // first element as default active
			collapsed: true,
			collapseInit: false,
		};

		this.blog = blog;

		this.onScroll = throttle(this.onScroll, 30, { trailing: true });
		this.onResize = throttle(this.onResize, 500, { trailing: true });

		this.init();
		return this;
	}

	init() {
		this.attachListeners();
		this.updateToggleText();
		this.onResize();
	}

	attachListeners() {
		window.addEventListener("scroll", this.onScroll.bind(this), false);

		this.e.filterOptions.forEach((option) => {
			option.addEventListener("change", (event) => {
				event.preventDefault();
				this.onChecked(option);
			});
		});

		this.e.toggle.addEventListener("click", () => {
			this.toggleCollapse();
		});
		window.addEventListener("resize", () => {
			this.onResize();
		});
	}

	// expand / collapse for mobile
	toggleCollapse() {
		this.e.filter.classList.toggle(this.options.expandedClass);
		if (this.state.collapsed) {
			this.e.toggle.setAttribute("aria-expanded", true);
			this.e.filterOptions.forEach((option) => {
				option.tabIndex = 0;
			});
		} else {
			this.e.toggle.setAttribute("aria-expanded", false);
			this.e.filterOptions.forEach((option) => {
				option.tabIndex = -1;
			});
		}
		this.state.collapsed = !this.state.collapsed;
	}

	/**
	 * Change the toggle text to match the text of the selected filter
	 *
	 * @param   {node}  button  selected button
	 */
	updateToggleText() {
		console.log(this.state.selectedFilters);
		const activeButtons = this.e.filter.querySelectorAll(
			"." + this.options.buttonActiveClass
		);

		if (activeButtons.length > 0) {
			this.e.toggleText.innerHTML = Array.from(activeButtons)
				.map((v) => {
					return v.dataset.filter;
				})
				.join(", ");
		}
	}

	/**
	 * Test if to init the collapsed menu or to remove all the aria-values for the collapsed menu when changing to uncollapsed version
	 */
	onResize() {
		if (
			window.breakpoint.value < this.options.collapseBreakpoint &&
			!this.state.collapseInit
		) {
			this.initCollapse();
		} else if (
			window.breakpoint.value >= this.options.collapseBreakpoint &&
			this.state.collapseInit
		) {
			this.destroyCollapse();
		}
	}

	// add accesibility attributes for collapse and update state
	initCollapse() {
		this.e.filter.classList.remove(this.options.expandedClass);
		this.e.toggle.setAttribute("aria-expanded", false);
		this.e.filterOptions.forEach((button) => {
			button.tabIndex = -1;
		});
		this.state.collapsed = true;
		this.state.collapseInit = true;
	}

	// remove accesibility attributes for collapse and update state
	destroyCollapse() {
		this.e.toggle.removeAttribute("aria-expanded");
		this.e.filterOptions.forEach((button) => {
			button.tabIndex = 0;
		});
		this.state.collapsed = true;
		this.state.collapseInit = false;
	}

	/**
	 * get the filter data-attribute of the button and then call the .onFilter method of the blog instance
	 *
	 * @param   {node}  button  button element
	 */
	onChecked(option) {
		const category = option.value;
		if (this.state.selectedFilters.includes(category) && option.checked) {
			return;
		}

		const toggleAll = this.e.filterOptionAll;

		if (category === "all" && !this.state.selectedFilters.includes("all")) {
			this.e.filterOptions.forEach((checkbox) => {
				this.toggleCheck(checkbox, false);
			});

			this.toggleCheck(toggleAll, true);
			this.state.selectedFilters = [];
		}

		if (
			(category !== "all" && toggleAll.checked) ||
			(category !== "all" && this.state.selectedFilters.includes("all"))
		) {
			this.toggleCheck(toggleAll, false);
			this.state.selectedFilters = this.state.selectedFilters.filter(
				(filter) => filter !== "all"
			);
		}

		this.toggleCheck(option);

		if (option.checked) {
			this.state.selectedFilters.push(category);
		} else {
			this.state.selectedFilters = this.state.selectedFilters.filter(
				(filter) => filter !== category
			);
			// checks for deselecting only set filter
			if (this.state.selectedFilters.length === 0) {
				toggleAll.checked = true;
				toggleAll.classList.add(this.options.buttonActiveClass);
				this.state.selectedFilters = ["all"];
			}
		}

		this.updateToggleText();

		if (!this.state.collapsed) {
			this.toggleCollapse();
		}

		this.blog.onFilter(this.state.selectedFilters);
	}

	/**
	 *
	 * @param {HTMLInputElement} checkbox
	 * @param {boolean} checked otional as override
	 */
	toggleCheck(checkbox, checked) {
		const optionLabel = checkbox.parentNode.querySelector(
			this.s.filterButton
		);
		if (checked ?? checkbox.checked) {
			checkbox.checked = true;
			optionLabel.classList.add(this.options.buttonActiveClass);
		} else {
			checkbox.checked = false;
			optionLabel.classList.remove(this.options.buttonActiveClass);
		}
	}

	scrollTop() {
		this.e.filter.scrollIntoView({ behavior: "smooth" });
	}
}

class DateNav {
	/**
	 * The DateNav will be initiated from the blog instance
	 *
	 * @param   {node}  el    datenav element
	 * @param   {object}  blog  blog class instance
	 *
	 * @return  {object}        instance of new date-nav
	 */
	constructor(el, blog) {
		this.s = {
			button: ".js-date-nav__button",
			blogpost: ".js-blogpost",
			postsContainer: ".js-blog__posts-container",
		};

		this.e = {
			main: el,
			buttons: el.querySelectorAll(this.s.button),
			postsContainer: blog.e.main.querySelector(this.s.postsContainer),
			yearButtons: [], // see this.sortButtons
		};

		this.options = {
			activeButtonClass: "date-nav__button--active",
			buttonDisabledClass: "date-nav__button--disabled",
		};

		this.state = {
			currentYear: null,
			currentMonth: null,
		};

		this.Blog = blog;
		this.intersectionObserver = null;
		this.mutationObserver = null;

		this.init();
		return this;
	}

	init() {
		this.sortButtons();
		this.initIntersectionObserver();
		this.initMutationObserver();
		this.e.buttons.forEach((button) => {
			button.addEventListener("click", (event) => {
				this.onButtonClick(button);
			});
		});
		this.activateFirst();
	}

	// Just filter out all year-buttons
	sortButtons() {
		this.e.buttons.forEach((button) => {
			if (button.dataset.year) {
				this.e.yearButtons.push(button);
			}
		});
	}

	/**
	 * Watch for mutations of the blog-post container
	 * When mutaction observed, observe or unobserve new posts with intersection observer to toggle state of scrollpsy / date-nav buttons
	 */
	initMutationObserver() {
		const config = { childList: true };
		this.mutationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				mutation.addedNodes.forEach((node) => {
					this.intersectionObserver.observe(node);
				});
				mutation.removedNodes.forEach((node) => {
					if (node.nodeType === 1) {
						this.intersectionObserver.unobserve(node);
					}
				});
			});
		});
		this.mutationObserver.observe(this.e.postsContainer, config);
	}

	/**
	 * Intersection Observer observing all blog posts
	 * On intersection we call onPostInView()
	 *
	 * @return  {[type]}  [return description]
	 */
	initIntersectionObserver() {
		const initialPosts = this.e.postsContainer.querySelectorAll(
			this.s.blogpost
		);
		const options = { rootMargin: "0px 0px -70% 0px" };
		this.intersectionObserver = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.onPostInView(entry.target);
					}
				});
			},
			options
		);
		initialPosts.forEach((post) => {
			this.intersectionObserver.observe(post);
		});
	}

	activateFirst() {
		this.onPostInView(this.e.postsContainer.querySelector(this.s.blogpost));
	}

	// When post is in view get year and month and change corresponding state of date-nav
	onPostInView(post) {
		let date = post.dataset.date;
		let year = date.substring(0, 4);
		let month = date.substring(4, 6);

		this.updateNavState(year, month);
	}

	/**
	 * Update active-style of buttons
	 * Acepts just a year or year and month as argumet
	 *
	 * @param   {string}  year   'YYYY'
	 * @param   {string}  month  'MM'
	 */
	updateNavState(year, month) {
		if (!year || !month) {
			return;
		}
		if (
			year === this.state.currentYear &&
			month === this.state.currentMonth
		) {
			return;
		}
		this.e.buttons.forEach((button) => {
			button.classList.remove(this.options.activeButtonClass);
		});

		for (let index = 0; index < this.e.buttons.length; index++) {
			const button = this.e.buttons[index];
			if (button.dataset.year === year) {
				button.classList.add(this.options.activeButtonClass);
				let monthButtons = button.nextElementSibling.querySelectorAll(
					this.s.button
				);
				for (let index = 0; index < monthButtons.length; index++) {
					const button = monthButtons[index];
					if (button.dataset.month === month) {
						button.classList.add(this.options.activeButtonClass);
						return;
					}
				}
				return;
			}
		}
	}

	/**
	 * Disable all buttons, which dates are not corresponding to a month
	 * in the dates array
	 *
	 * @param   {array}  dates  ['YYYYMM', 'YYYYMM']
	 */
	updateButtonVisibilty(dates) {
		this.e.buttons.forEach((button) => {
			button.classList.add(this.options.buttonDisabledClass);
		});
		dates.forEach((date) => {
			const year = date.substring(0, 4);
			const month = date.substring(4);
			this.e.yearButtons.forEach((yearButton) => {
				if (yearButton.dataset.year === year) {
					yearButton.classList.remove(
						this.options.buttonDisabledClass
					);
					//look for month
					yearButton.nextElementSibling
						.querySelectorAll(this.s.button)
						.forEach((monthButton) => {
							if (monthButton.dataset.month === month) {
								monthButton.classList.remove(
									this.options.buttonDisabledClass
								);
							}
						});
				}
			});
		});
	}

	/**
	 * Find the first post in DOM with the requested year or year-month combination
	 *
	 * @param   {string}  date  'YYYY' || 'YYYYMM'
	 *
	 * @return  {node}        first matching blogpost
	 */
	findFirstPostByDate(date) {
		let posts = this.e.postsContainer.querySelectorAll(this.s.blogpost);

		if (date.length === 4) {
			for (let index = 0; index < posts.length; index++) {
				const post = posts[index];
				if (post.dataset.date.substring(0, 4) === date) {
					return post;
				}
			}
		} else if (date.length === 6) {
			for (let index = 0; index < posts.length; index++) {
				const post = posts[index];
				if (post.dataset.date.substring(0, 6) === date) {
					return post;
				}
			}
		}
		return false;
	}

	onButtonClick(button) {
		let post = null;
		let date;
		if (button.dataset.year) {
			date = button.dataset.year;
		} else if (button.dataset.month) {
			const year =
				button.closest("ul").previousElementSibling.dataset.year;
			date = year + button.dataset.month;
		}
		post = this.findFirstPostByDate(date);

		if (!post) {
			// no matchin gpost found -> request render of date-range of button
			this.Blog.showByDate(date).then(() => {
				post = this.findFirstPostByDate(date);
				this.scrollTo(post);
			});
		} else {
			this.scrollTo(post);
		}
	}

	scrollTo(post) {
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = post.getBoundingClientRect().top;
		const offsetPosition = elementRect - bodyRect - 200;

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});
	}
}

class Blog {
	constructor(main) {
		this.s = {
			filter: ".js-filter",
			dateNav: ".js-date-nav",
			postContainer: ".js-blog__posts-container",
			blogpost: ".js-blogpost",
			showMore: ".js-blog__loadmore",
		};

		this.e = {
			main: main,
			filter: main.querySelector(this.s.filter),
			dateNav: main.querySelector(this.s.dateNav),
			postContainer: main.querySelector(this.s.postContainer),
			posts: main.querySelectorAll(this.s.blogpost),
			showMore: main.querySelector(this.s.showMore),
		};

		this.options = {
			postPath: blogPath,
			showMoreCount: 2, // how many posts to add on showmore
			showInitialCount: 5, // how many to show when filtering
			animationDuration: 500,
			postHiddenClass: "blogpost__section--fadeOut",
			showMoreHiddenClass: "blog__loadmore--hidden",
		};

		this.state = {
			posts: [], // initial rendered posts - this will be updated on fetch and all available posts will be pushed
			currentSelection: [], // post nodes of the selected filter
			selectedFilters: [], // []string -> name of filter selected
			postsFetched: false,
			lastDisplayedPost: null, // reference to last post in DOM
			observedPost: null, // post which is observed by intersection observer
			autoload: this.e.main.classList.contains("js-blog--autoload"), // only then new posts will be loaded when reaching the last post
		};

		this.intersectionObserver = null; // will track when the last post comes into view

		this.Filter = null;
		this.DateNav = null;

		this.init();
		return this;
	}

	init() {
		if (!this.e.posts || !this.options.postPath) {
			return;
		}
		// setupt array of initially loaded posts
		this.state.posts =
			this.e.posts.length > 1 ? [...this.e.posts] : [this.e.posts[0]];

		//init filter if present in blog-element
		if (this.e.filter) {
			this.Filter = new Filter(this.e.filter, this);
		}

		// init sticky date-nav if present in blog-element
		if (this.e.dateNav) {
			this.DateNav = new DateNav(this.e.dateNav, this);
		}

		this.setupObserver();
		this.updateObserver(this.e.posts[this.e.posts.length - 1]);
	}

	setupObserver() {
		const options = { rootMargin: "0px" };
		this.intersectionObserver = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && this.state.autoload) {
						this.showMore();
					}
				});
			},
			options
		);
	}

	/**
	 * Unobserve currently observed post and observe provided post element
	 * Should be updated after every render
	 *
	 * @param   {node}  postToObserve  post which should be observed by intersection observer
	 */
	updateObserver(postToObserve) {
		if (this.state.observedPost) {
			this.intersectionObserver.unobserve(this.state.observedPost);
		}
		this.intersectionObserver.observe(postToObserve);
		this.state.observedPost = postToObserve;
	}

	onFilter(categories) {
		if (!this.state.postsFetched) {
			this.getPosts().then(() => {
				this.filter(categories);
			});
		} else {
			this.filter(categories);
		}
	}

	/**
	 * Filter all posts from post-array which match the catefory
	 * Render new selection
	 *
	 * @param {[]string}  categories  values of filter-attributes
	 */
	filter(categories) {
		categories = categories || ["all"];

		let newSelection = [];
		this.state.posts.forEach((post) => {
			if (categories.includes("all")) {
				newSelection.push(post);
				return;
			}

			const postFilters = post.dataset.filter.split(",");
			if (
				postFilters.some((postFilter) =>
					categories.includes(postFilter.trim())
				)
			) {
				newSelection.push(post);
			}
			//if (post.dataset.filter === category || category === 'all') {
			//	newSelection.push(post);
			//}
		});
		this.state.currentSelection = newSelection;
		this.DateNav &&
			this.DateNav.updateButtonVisibilty(
				this.getPostDateRange(newSelection)
			);
		this.renderPosts(true).then(() => {
			this.DateNav && this.DateNav.activateFirst();
		});
	}

	/**
	 * collect all occuring dates in the format YYYYMM of a collection of posts
	 *
	 * @param   {array}  posts  array of posts to collect dates from
	 *
	 * @return  {array}         array with all occuring dates in Format YYYYMM
	 */
	getPostDateRange(posts) {
		let dateRange = [];
		posts.forEach((post) => {
			const postDateRange = post.dataset.date.substring(0, 6);

			if (!dateRange.includes(postDateRange)) {
				dateRange.push(postDateRange);
			}
		});
		return dateRange;
	}

	/**
	 * Called by intersection observer watching last post in DOM
	 * Fetch posts if not already done and then render more posts
	 */
	showMore() {
		if (
			this.state.currentSelection.length != 0 &&
			this.e.postContainer.querySelectorAll(this.s.blogpost).length >=
				this.state.currentSelection.length
		) {
			// return because all to display already displayed
			return;
		}

		if (!this.state.postsFetched) {
			this.getPosts().then(() => {
				this.renderPosts(false);
			});
		} else {
			this.renderPosts(false);
		}
	}

	/**
	 * Request display of all posts up to provided date
	 *
	 * @param   {string}  dateRange  'YYYY' || 'YYYYMM'
	 *
	 * @return  {promise}             reolved when insertion of posts is complete
	 */
	showByDate(dateRange) {
		return new Promise((resolve, reject) => {
			if (!this.state.postsFetched) {
				this.getPosts().then(() => {
					this.renderPosts(false, dateRange).then(() => {
						resolve();
					});
				});
			} else {
				this.renderPosts(false, dateRange).then(() => {
					resolve();
				});
			}
		});
	}

	/**
	 * change displayed posts
	 *
	 * @param   {Boolean}  newSelection  if false just show more of existing
	 * @param   {string}  dateRange  a reqested date-string in the format 'YYYY' || 'YYYYMM'
	 *
	 * * @return  {promise}             reolved when insertion of posts is complete
	 */
	renderPosts(newSelection, dateRange) {
		newSelection = newSelection || false;
		dateRange = dateRange || false;

		if (!this.state.currentSelection) {
			return;
		}

		return new Promise((resolve, reject) => {
			if (!newSelection) {
				//show more
				let startIndex = this.e.main.querySelectorAll(
					this.s.blogpost
				).length;
				let endIndex = startIndex + this.options.showMoreCount;

				if (dateRange) {
					endIndex = this.getPostIndex(dateRange) + 1;
				}
				let additionalPosts = this.state.currentSelection.slice(
					startIndex,
					endIndex
				);
				this.appendPosts(additionalPosts);
				// observe new last element
				let posts = this.e.postContainer.querySelectorAll(
					this.s.blogpost
				);
				let last = posts[posts.length - 1];
				this.updateObserver(last);
				resolve();
			} else {
				// show new set of posts
				this.fadeOutPosts().then(() => {
					this.removePosts();
					let newPosts = this.state.currentSelection.slice(
						0,
						this.options.showInitialCount
					);
					this.appendPosts(newPosts);
					// observe new last element
					let posts = this.e.postContainer.querySelectorAll(
						this.s.blogpost
					);
					let last = posts[posts.length - 1];
					this.updateObserver(last);
					resolve();
				});
			}
		});
	}

	/**
	 * Loop backwards trough posts to find the last oldest post, which matches the dateRange
	 *
	 * @param   {string}  dateRange  Year or Year and Month: 'YYYY' || 'YYYYMM'
	 *
	 * @return  {node}             matching post
	 */
	getPostIndex(dateRange) {
		let onlyYear = dateRange.length <= 4;
		for (
			let index = this.state.currentSelection.length - 1;
			index >= 0;
			index--
		) {
			const post = this.state.currentSelection[index];
			if (onlyYear && post.dataset.date.substring(0, 4) === dateRange) {
				return index;
			} else if (post.dataset.date.substring(0, 6) === dateRange) {
				return index;
			}
		}
	}

	/**
	 * Apply hidden-class to posts
	 * Returns promise which gets resolved after transition complete
	 *
	 * @return  {promise}        promise gest resolved after timeout of transition-time
	 */
	fadeOutPosts() {
		return new Promise((resolve, reject) => {
			let posts = this.e.postContainer.querySelectorAll(this.s.blogpost);
			posts.forEach((post) => {
				post.classList.add(this.options.postHiddenClass);
			});
			setTimeout(() => {
				resolve();
			}, this.options.animationDuration);
		});
	}

	/**
	 * add posts
	 * Add and remove hiddenClass for fade-in transition
	 *
	 * @param   {array}  posts  array with post nodes to apped
	 */
	appendPosts(posts) {
		posts.forEach((post) => {
			post.classList.add(this.options.postHiddenClass);
			this.e.postContainer.appendChild(post);
			initSlideshows(post); //see slidesho.js
			setTimeout(() => {
				post.classList.remove(this.options.postHiddenClass);
			}, 0);
		});
	}

	/**
	 * Empty post container element
	 */
	removePosts() {
		if (this.DateNav) {
			let posts = this.e.postContainer.querySelectorAll(this.s.blogpost);
			//this.DateNav.removeObserver(posts)
		}
		while (this.e.postContainer.firstChild) {
			this.e.postContainer.removeChild(this.e.postContainer.firstChild);
		}
	}

	/**
	 * merge new posts with posts array while not adding
	 * test data-id of posts to avoid duplicates
	 *
	 * @param   {array}  posts  array of new posts to merge with existing array
	 */
	mergePosts(posts) {
		let uuidsExisting = [];
		this.state.posts.forEach((post) => {
			uuidsExisting.push(post.dataset.id);
		});

		posts.forEach((post) => {
			if (!uuidsExisting.includes(post.dataset.id)) {
				this.state.posts.push(post);
			}
		});
	}

	/**
	 * Fetch and parse page with all posts
	 */
	getPosts() {
		return this.fetch(this.options.postPath).then((text) => {
			const parser = new DOMParser();
			const doc = parser.parseFromString(text, "text/html");
			let posts = doc.querySelectorAll(this.s.blogpost);

			this.mergePosts(posts);
			this.state.currentSelection = this.state.posts; // at this point no filter possible; so all posts are in current selection
			this.state.postsFetched = true;
		});
	}

	/**
	 * Actual fetch of posts
	 *
	 * @param   {string}  path  path of url to fetch
	 *
	 */
	fetch(path) {
		return fetch(path, {
			credentials: "same-origin",
		})
			.then((response) => {
				if (!response.ok) {
					console.debug(response.statusText);
					return;
				}
				return response.text();
			})
			.catch((err) => this.handleError(err, path));
	}

	handleError(err) {
		console.log("Fetch Blogs handleError");
		console.error(err);
		return;
	}
}

function initBlogs() {
	let blogs = document.querySelectorAll(".js-blog");

	blogs.forEach((blog) => {
		new Blog(blog);
	});
}

initBlogs();

blogpostCollapse();

function blogpostCollapse(){

	//classes
	let c = {
		toggle: 'js-blogpost__readmore',
		collapse: 'js-blogpost__collapse',
		collapseOpenClass: 'blogpost__collapsed-content--open',
		toggleHiddenClass: 'blogpost__readmore--hidden'
	};

	//selectors
	let s = {
		blog: '.js-blog',
		toggle: '.' + c.toggle,
		collapse: '.' + c.collapse
	};

	let blog = document.querySelector(s.blog)
	let toggles = document.querySelectorAll(s.toggle);

	if (blog) {
		blog.addEventListener('click', (event) => {

			let toggle = event.target;
			if (toggle.classList.contains(c.toggle)) {
				event.preventDefault();
				let collapse = toggle.parentElement.parentElement.querySelector(s.collapse);
				// show content if collapsed content is available
				if (collapse.classList.contains(c.collapse)) {
					collapse.classList.add(c.collapseOpenClass);
					collapse.setAttribute('aria-hidden', false);
					collapse.tabIndex = 0;
					collapse.focus();
				}
				//hide button
				toggle.setAttribute('aria-expanded', 'true');
				toggle.disabled = true;
				toggle.tabIndex = -1;
				toggle.classList.add(c.toggleHiddenClass);
			}

		})
	}

	// only needed for posts which are not inside .js-blog eg. tester-page
	toggles.forEach( toggle => {
		toggle.addEventListener('click', event => {

			event.preventDefault();
			event.stopPropagation(); // no double execution when child of .js-log
			let collapse = toggle.parentElement.parentElement.querySelector(s.collapse);
			// show content if collapsed content is available
			if (collapse.classList.contains(c.collapse)) {
				collapse.classList.add(c.collapseOpenClass);
				collapse.setAttribute('aria-hidden', false);
				collapse.tabIndex = 0;
				collapse.focus();
			}
			//hide button
			toggle.setAttribute('aria-expanded', 'true');
			toggle.disabled = true;
			toggle.tabIndex = -1;
			toggle.classList.add(c.toggleHiddenClass);

		});
	});
}

window.dwmEmConsent =
	window.dwmEmConsent ||
	JSON.parse(localStorage.getItem("dwm-em-consent") || "{}");

$All(".js-consent-checkbox").forEach((checkbox) => {
	checkbox.checked = dwmEmConsent[checkbox.name] || false;

	checkbox.addEventListener("change", (e) => {
		dwmEmConsent[checkbox.name] = checkbox.checked;
		localStorage.setItem("dwm-em-consent", JSON.stringify(dwmEmConsent));
	});
});

let mainMenu = $('.js-menu');
let mobileOpenClass = 'menu--open';
let mobileMenuToggle = document.querySelector('.js-showmenu__toggle');
var mainMenuToggleLinks = $All('.js-toggle-menu__item > .js-disabled-link');
var mainMenuDisabledLinks = $All('.js-disabled-link');
var mainMenuToggleInput = $('.js-showmenu__input');


//
// Open Desktop Overlay Menu Items
//
[].forEach.call(mainMenuToggleLinks, function(el) {
	el.addEventListener('click', function(event) {
		event.preventDefault();

		var openItem = $('.js-toggle-menu__listitem.is-open');

		if (el.parentNode.classList.contains('is-open')) {
			el.parentNode.classList.remove('is-open');
		} else {
			if (openItem !== null) {
				openItem.classList.remove('is-open');
			}

			// close all other open Menus
			[].forEach.call(mainMenuToggleLinks, function(openElements) {
				openElements.parentNode.classList.remove('is-open');
			});

			el.parentNode.classList.add('is-open');
		}
	});
});



//
// Detect Click outside of Overlay Menu
//
document.addEventListener('click', function(event) {
	if (event.target.closest('.js-toggle-menu__item, .submenu')) return;

	var openItem = $('.js-toggle-menu__item.is-open');
	if (openItem !== null) {
		openItem.classList.remove('is-open');
	}
});



//
// Detect ESC Key to close Overlay Menu
//
document.addEventListener('keyup', function(event) {
	var key = event.which || event.keyCode;
	if (key === 27) {
		var openItem = $('.js-toggle-menu__item.is-open');
		if (openItem !== null) {
			openItem.classList.remove('is-open');
		}
	}
});



//
// Toggle mobile menu
// uses tua-body-scroll-lock to lock scrolling to menu
// https://github.com/tuateam/tua-body-scroll-lock
//

if (mobileMenuToggle != null ) {
	mobileMenuToggle.addEventListener('click', () => {
		if (mainMenu.classList.contains(mobileOpenClass)) {
			bodyScrollLock.clearBodyLocks();
		} else {
			//lock scrolling on open menu
			bodyScrollLock.lock(mainMenu);
		}
		mainMenu.classList.toggle(mobileOpenClass);
	});
}



//
// Close Mobile Menu on window.resize
//
window.addEventListener('resize', function(){
	// if (mainMenuToggleInput !== null && (mainMenuToggleInput.checked === true) ) {
	// 	mainMenuToggleInput.checked = false;
	// }

	//FIX im Mediaguide nicht vorhanden deshalb wird es fehler
	if(mainMenu){
		if (mainMenu.classList.contains(mobileOpenClass)) {
			mainMenu.classList.remove(mobileOpenClass);
			bodyScrollLock.clearBodyLocks();
		}
	}

});



//
// Open Desktop Overlay Menu Items
//
// [].forEach.call(mainMenuDisabledLinks, function(link) {
// 	link.addEventListener('click', function(event) {
// 	event.preventDefault();
// 	});
// });

/**
 * Image blend slider which lets the user compare to images by overlaying them and sliding the visible area of the top image
 */
class ImageBlend {
	/**
	 * constructor for image blend element
	 *
	 * @param   {node}  wrapperElement	wrapper for whole element
	 *
	 * @return  {ClassInstance}
	 */
	constructor(wrapperElement) {

		this.s = {
			mediaContainer: '.js-image-blend__media',
			imageContainer: '.js-image-blend__movable-image', // containing div of wwhich will be moved. img inside will be cut off
			indicator: '.js-image-blend__indicator',
			button: '.js-image-blend__button',
		};
		this.e = {
			wrapper: wrapperElement,
			mediaContainer: wrapperElement.querySelector(this.s.mediaContainer),
			imageContainer: wrapperElement.querySelector(this.s.imageContainer),
			indicator: wrapperElement.querySelector(this.s.indicator),
			button: wrapperElement.querySelector(this.s.button),
		};

		if (this.e.mediaContainer && this.e.imageContainer && this.e.button) {
			this.init();
		}
		return this;

	}

	init() {
		this.e.button.addEventListener('pointerdown', onPressed, {passive: true});
		window.addEventListener('resize', () => {
			calculateDimensions();
			update(lastPointerX);
		});
		let self = this;

		let lastPointerX = window.innerWidth / 2; // used for calculations on resize; default value center
		let mediaRect, mediaCenter, buttonTranslateMax, imageRight, buttonTranslate, raf;
		let multiply = 1;

		calculateDimensions();

		function calculateDimensions(){
			mediaRect = self.e.mediaContainer.getBoundingClientRect();
			mediaCenter = mediaRect.right - (mediaRect.width / 2);
			buttonTranslateMax = mediaRect.width / 2;
		}

		function onPressed(event) {
			multiply = document.body.classList.contains('rotate180') ? -1 : 1;
			document.body.addEventListener('pointermove', onMove, {passive: true});
			document.body.addEventListener('touchmove', onMove, {passive: true});
			document.body.addEventListener('pointerup', onRelease, {passive: true});
			document.body.addEventListener('touchend', onRelease, {passive: true});

		}

		// simple clamp function to prevent values out of bounds
		function clamp(num, min, max){
			return num <= min ? min : num >= max ? max : num;
		}

		/**
		 * calculate the translate-value for our indicator / button andalso the position-right value for the image-container
		 * based on pointer position
		 *
		 * @param   {number}  pointerX  Pointer x-position
		 *
		 * @return  {array}            [buttonTranslate, imageCOntainerRight]
		 */
		function getValues(pointerX){
			let imageRight;
			if (multiply < 0) {
				imageRight = clamp( ((pointerX - mediaRect.left) * 100 / mediaRect.width) , 0, 100);
			} else {
				imageRight = clamp( ((mediaRect.right - pointerX) * 100 / mediaRect.width) , 0, 100); // image-container positionl-right value
			}
			return [
				clamp(pointerX - mediaCenter, -buttonTranslateMax, buttonTranslateMax) * multiply, // button translate
				imageRight,
			];
		}

		function onMove(event) {
			let xVal = event.clientX || event.pageX || event.touches[0].pageX;
			update(xVal);
		}

		function update(pointerX) {
			if (!raf) {
				lastPointerX = pointerX;
				let transformValues = getValues(pointerX);
				buttonTranslate = transformValues[0];
				imageRight = transformValues[1];
				raf = requestAnimationFrame(onMoveRaf);
			}
		}

		function onMoveRaf() {
			self.e.indicator.style.transform = 'translateX(' + buttonTranslate +  'px)';
			self.e.imageContainer.style.right = imageRight + '%';
			raf = null;
		}

		function onRelease(event) {
			document.body.removeEventListener('pointermove', onMove);
			document.body.removeEventListener('touchmove', onMove);
			document.body.removeEventListener('pointerup', onRelease);
			document.body.removeEventListener('touchend', onRelease);

			// stop animation frame when user iteraction already stopped
			if (raf) {
			  cancelAnimationFrame(raf);
			  raf = null;
			}
		}
	}

}

function initImageBlend() {

	const s = {
		element: '.js-image-blend'
	};
	const imageBlendELements = document.querySelectorAll(s.element);
	imageBlendELements.forEach(function (element) {
		new ImageBlend(element);
	});

}

initImageBlend();

/**
 * map with new s4 route and new stations
 */
// esversion: 6
class InteractiveMap {
	/**
     * constructor for interactive map element
     *
     * @param   {node}  main	map wrapper element
     *
     * @return  {ClassInstance}
     */
    constructor(main) {

        this.s = {
			mapWrapper: '.js-interactive-map__wrapper',
			tooltip: '.js-interactive-map__tooltip',
			tooltipTitle: '.js-interactive-map__tooltip-title',
			tooltipSubtitle: '.js-interactive-map__tooltip-subtitle',
			tooltipToggle: '.js-interactive-map__tooltip-item',
        };
        this.e = {
            main: main,
			mapWrapper: main.querySelector(this.s.mapWrapper),
			tooltip: main.querySelector(this.s.tooltip),
			tooltipTitle: main.querySelector(this.s.tooltipTitle),
			tooltipSubtitle: main.querySelector(this.s.tooltipSubtitle),
			toggle: main.querySelectorAll(this.s.tooltipToggle),
        };
		this.options = {
			tooltipActiveClass: 'interactive-map__tooltip--active',
			tooltipBreakpoint: 1024 // no tooltips on smaller screens
		};
		this.state = {
			openTooltip: false,
			mapRect: this.e.mapWrapper.getBoundingClientRect(),
			inMenu: this.e.main.classList.contains('interactive-map--menu')
		};

		this.onResize = this.onResize.bind(this);
		this.throttledResize = throttle(this.onResize, 500, {'trailing': true});
		this.documentClick = this.documentClick.bind(this);

        this.init();
        return this;

    }

    init() {
		// no tooltips in menu
		if (!this.state.inMenu) {
			this.addListeners();
		}
    }

	addListeners(){

		this.e.toggle.forEach( toggle => {
			toggle.addEventListener('click', (event) => {
				this.toggleClick(toggle, event);
			});
			toggle.addEventListener('mouseenter', (event) => {
				this.toggleClick(toggle, event);
			});
			toggle.addEventListener('mouseleave', (event) => {
				this.hideTooltip();
			});
		});

		window.addEventListener('resize', this.throttledResize)

	}

	// click on station marker
	toggleClick(toggle, event) {
		event.preventDefault();
		this.toggleTooltip(toggle);
	}

	toggleTooltip(toggle) {

		if (this.state.openTooltip === toggle) {
			// same already open
			this.hideTooltip();
		} else if (this.state.openTooltip) {
			// other tooltip open
			this.showTooltip(toggle);
		} else {
			// no tooltip open
			this.showTooltip(toggle);
		}

	}

	/**
	 * remove class which shows tooltip
	 * remove event listener for click outside
	 */
	hideTooltip(){
		document.removeEventListener('click', this.documentClick);
		this.e.tooltip.classList.remove(this.options.tooltipActiveClass);
		this.state.openTooltip = false;
	}

	/**
	 * set position and content of tooltip based on clicked toggle element
	 * add event listener to listen for cliks outside of tooltip
	 *
	 * @param   {node}  toggle  clicked toggle
	 */
	showTooltip(toggle){

		if (breakpoint.value < this.options.tooltipBreakpoint) {
			return;
		}
		this.setTooltipContent(toggle);
		this.setTooltipPosition(toggle);
		this.e.tooltip.classList.add(this.options.tooltipActiveClass);
		if (!this.state.openTooltip) {
			document.addEventListener('click', this.documentClick);
		}
		this.state.openTooltip = toggle;

	}

	/**
	 * get title and subtitle from dataattributes of clicked element and set content of tooltip
	 *
	 * @param   {node}  toggle  clicked element
	 */
	setTooltipContent(toggle) {

		let title = toggle.dataset.title || '';
		let subtitle = toggle.dataset.subtitle || '';
		this.e.tooltipTitle.innerText = title;
		this.e.tooltipSubtitle.innerText = subtitle;

	}

	/**
	 * calcuate position of tooltip relative to container element
	 * based on position of clicked element
	 *
	 * @param   {node}  toggle  clicked toggle
	 */
	setTooltipPosition(toggle) {

		let toggleRect = toggle.getBoundingClientRect();
		this.state.mapRect = this.e.mapWrapper.getBoundingClientRect();

		//position left
		let ls = toggleRect.left + (toggleRect.width / 2) - this.state.mapRect.left;
		let lt = this.state.mapRect.width;
		let left = ls / lt * 100;

		//position top
		let hs = toggleRect.top + (toggleRect.height / 2) - this.state.mapRect.top;
		let ht = this.state.mapRect.height;
		let top = hs / ht * 100;

		this.e.tooltip.style.left = left + '%';
		this.e.tooltip.style.top = top + '%';

	}

	/**
	 * test for clicks outside of toggle and close tooltip if outside
	 *
	 * @param   {object}  event
	 *
	 */
	documentClick(event) {
		if (!(event.target.classList.contains('js-interactive-map__tooltip-item') || event.target.parentElement.classList.contains('js-interactive-map__tooltip-item'))) {
			this.hideTooltip();
		}
	}

	onResize(){
		this.state.mapRect = this.e.mapWrapper.getBoundingClientRect();
	}



}

function initInteractiveMaps() {

    const s = {
        selectorMap: '.js-interactive-map'
    };
    const maps = document.querySelectorAll(s.selectorMap);
    maps.forEach(function (map) {
        new InteractiveMap(map);
    });

}

initInteractiveMaps();

/**
 * map with possible horizontal scrolling
 */
// esversion: 6
class ScrollableMap {
	/**
     * constructor for map element
     *
     * @param   {node}  main	map wrapper element
     *
     * @return  {ClassInstance}
     */
    constructor(main) {

        this.s = {
			imageWrapper: '.js-map__wrapper',
            left: '.js-map__button-left',
            right: '.js-map__button-right',
        };
        this.e = {
            main: main,
			mapWrapper: main.querySelector(this.s.imageWrapper),
            left: main.querySelector(this.s.left),
			right: main.querySelector(this.s.right),
			mapImage: main.querySelector('img')
        };
		this.options = {
			scrollableRightClass: 'map--scrollable-right',
			scrollableLeftClass: 'map--scrollable-left'
		};

		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.throttledScroll = throttle(this.onScroll, 200, {'trailing': true});

        this.init();
        return this;

    }

    init() {
		this.addListeners();

    }

	addListeners(){

		if (this.e.mapImage) {
			this.e.mapImage.addEventListener('load', this.onImageLoaded);
		}

		this.e.mapWrapper.addEventListener('scroll', this.throttledScroll);

		this.e.left.addEventListener('click', () => { this.scroll('left') });
		this.e.right.addEventListener('click', () => { this.scroll('right') });

		window.addEventListener("resize", this.throttledScroll, false);

	}

	/**
	 * Scroll the map by a certain amount to right or left
	 *
	 * @param   {string}  direction  right || left
	 */
	scroll(direction) {

		direction = direction || 'right';

		let position = (direction === 'right')
			? this.e.mapWrapper.scrollLeft + 350
			: this.e.mapWrapper.scrollLeft - 350;

			this.e.mapWrapper.scroll({
			left: position,
			top: 0,
			behavior: 'smooth'
		});

	}

	onImageLoaded(){
		this.checkScrollable();
	}

	onScroll(){
		this.checkScrollable();
	}

	/**
	 * Test if scroll left or right buttons should be shown
	 * applies or removes modifier-classes to wrapper element to hide or show buttons
	 */
	checkScrollable(){

		let scrollable = this.e.mapWrapper.scrollWidth > this.e.mapWrapper.clientWidth ? true : false;

		if (scrollable) {

			if ((this.e.mapWrapper.scrollLeft + this.e.mapWrapper.offsetWidth) < this.e.mapWrapper.scrollWidth) {
				// scrollable to right
				this.e.main.classList.add(this.options.scrollableRightClass);
			} else {
				this.e.main.classList.remove(this.options.scrollableRightClass);
			}

			if (this.e.mapWrapper.scrollLeft > 0 ) {
				// scrollable to left
				this.e.main.classList.add(this.options.scrollableLeftClass);
			} else {
				this.e.main.classList.remove(this.options.scrollableLeftClass);
			}

		}

	}



}

function initMaps() {

    const s = {
        selectorMap: '.js-map'
    };
    const maps = document.querySelectorAll(s.selectorMap);
    maps.forEach(function (map) {
        new ScrollableMap(map);
    });

}

initMaps();

/**
 * Slideshow using tiny-slider
 * https://github.com/ganlanyuan/tiny-slider
 */
class Slideshow {
	/**
	 * constructor for slideshow element
	 *
	 * @param   {NodeList}  elem	slideshow wrapper element
	 *
	 * @return  {ClassInstance}
	 */
	constructor(slider) {
		this.s = {
			sliderWrapper: ".js-slideshow__wrapper",
			slide: ".js-slideshow__slide",
			prev: ".js-slideshow__prev",
			next: ".js-slideshow__next",
		};
		this.e = {
			slider: slider,
			sliderWrapper: slider.querySelector(this.s.sliderWrapper),
			slides: slider.querySelectorAll(this.s.slide),
			prev: slider.querySelector(this.s.prev),
			next: slider.querySelector(this.s.next),
		};
		this.options = {
			initClass: "slideshow--init",
		};

		this.init();
		return this;
	}

	init() {
		if (this.e.slider.classList.contains(this.options.initClass)) {
			return;
		}
		this.initSlider();
		this.e.slider.classList.add(this.options.initClass);
	}

	/**
	 * Setup of slider. See
	 * https://github.com/ganlanyuan/tiny-slider
	 * for setup information
	 */
	initSlider() {
		this.slider = tns({
			container: this.e.sliderWrapper,
			items: 1,
			mouseDrag: true,
			nav: false,
			controls: true,
			prevButton: this.e.prev,
			nextButton: this.e.next,
			autoplay: false,
			arrowKeys: true,
			autoplayHoverPause: true,
			preventScrollOnTouch: "auto",
			gutter: 40,
		});
	}
}

/**
 * initSlideshows with option of target element in which to look for slidesows
 * Target Options used for dynamic loaded content eg. blogposts
 *
 * @param   {node}  target  will look for slideshows inside this target
 *
 */
function initSlideshows(target) {
	const s = {
		selectorSlideshows: ".js-slideshow",
	};
	const slideshows = (target || document).querySelectorAll(
		s.selectorSlideshows
	);
	slideshows.forEach(function (slideshow) {
		new Slideshow(slideshow);
	});
}

initSlideshows();

class StickyCam {
	constructor(el) {
		this.stickyCam = el;
		this.stickyCamBox = this.stickyCam.querySelector(".stickyCam__box");
		this.stickyCamToggle =
			this.stickyCam.querySelectorAll(".stickyCam__toggle");

		if (!this.stickyCam || !this.stickyCamBox || !this.stickyCamToggle) {
			return;
		}

		this.initToggle();
		this.setDate();

		if (this.isActive()) {
			this.addIframe();
		}
	}

	initToggle = () => {
		this.stickyCamToggle.forEach((toggle) => {
			toggle.addEventListener("click", () => {
				if (this.isActive()) {
					this.stickyCam.removeAttribute("data-active");
					this.removeIframe();
				} else {
					this.stickyCam.dataset.active = "";
					this.addIframe();
				}
			});
		});
	};

	setDate() {
		const date = new Date();

		this.stickyCam.querySelector(".js-stickyCam-date").textContent =
			date.toLocaleDateString("de-DE", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			});
	}

	addIframe = () => {
		let element;
		const { src } = this.stickyCam.dataset;

		if (!src) return;

		if (src.match(/\.(jpe?g|png|gif|bmp)$/)) {
			element = document.createElement("img");
			element.classList.add("stickyCam__img");
		} else {
			element = document.createElement("iframe");
			element.setAttribute("frameborder", "0");
			element.setAttribute("scrolling", "no");
			element.classList.add("stickyCam__iframe");
		}

		element.src = this.stickyCam.dataset.src;
		this.stickyCamBox.appendChild(element);
	};

	removeIframe = () => {
		setTimeout(
			() =>
				this.stickyCamBox.querySelector(".stickyCam__iframe")?.remove(),
			100
		);
	};

	isActive = () => this.stickyCam.hasAttribute("data-active");
}

document.addEventListener("DOMContentLoaded", () => {
	const stickyCams = document.querySelectorAll(".stickyCam");

	stickyCams.forEach((stickyCam) => new StickyCam(stickyCam));
});


//
// Timeline
//
const timelinewrapper = document.querySelector('.js-timelinewrapper');
const timeline = document.querySelectorAll('.js-timeline');
const verticalline = document.querySelectorAll('.js-timeline__verticalline');
const timelineAndMilestones = document.querySelectorAll('.js-timeline, .js-timeline__milestone');

function setTimelineImages(parent) {
	if( parent.getElementsByClassName('js-timeline__media')[0] != null && breakpoint.value >= 800 ){
		const timelineItem = parent.parentNode;
		const imagewrapper = timelineItem.getElementsByClassName('js-timeline__media')[0];
		const image = imagewrapper.getElementsByClassName('image')[0];
		const buttonHeight = timelineItem.getElementsByClassName('timeline__title')[0].clientHeight;
		const imagewrapperHeight = imagewrapper.clientHeight;

		timelineItem.style.minHeight = imagewrapperHeight + buttonHeight + 'px';
		imagewrapper.style.top = buttonHeight + 'px';
		imagewrapper.style.height = imagewrapperHeight + 'px';
	}
}

function setTimelineImagesOnResize() {
	const openAccordion = document.querySelectorAll('.js-accordion.is--open');
	if(openAccordion != null){
		openAccordion.forEach(function(el){
			if(el.getElementsByClassName('js-timeline__media')[0] != null){
				if(breakpoint.value < 800) {
					el.parentNode.style.minHeight = 'auto';
					el.getElementsByClassName('js-timeline__media')[0].style.cssText = 'top: auto; height: auto;';
					el.getElementsByClassName('js-accordion__collapse')[0].style.height = 'auto';
				} else {
					const imagewrapper = el.getElementsByClassName('js-timeline__media')[0];
					const imagewrapperHeight = imagewrapper.clientHeight;
					const buttonHeight = el.getElementsByClassName('timeline__title')[0].clientHeight;

					el.parentNode.style.minHeight = imagewrapperHeight + buttonHeight + 'px';
					el.getElementsByClassName('image')[0].classList.add('is--visible');
				}
			}
		});
	}
}



function startTimeline() {
	const counterTimelines = timeline.length;
	const firstButtonHeight = timeline[0].getElementsByClassName('timeline__title')[0].clientHeight;
	const timelinewrapperHeight = timelinewrapper.clientHeight;
	const verticallinePosY = ((firstButtonHeight / 2) - 2);
	const lastTimelineItemHeight = timelinewrapper.getElementsByClassName('js-timeline')[counterTimelines - 1].clientHeight;
	let verticallineHeight = timelinewrapperHeight - verticallinePosY;

	// Open item on Page Load
	timeline.forEach(function(el){
		if(el.classList.contains('is--openOnPageload')) {
			toggleAccordionItem(el.getElementsByClassName('js-accordion__button')[0]);
			el.classList.remove('is--openOnPageload');
		}
	});

	if(breakpoint.value >= 800) {
		// verticallineHeight = '100%';
		verticalline[0].style.top = verticallinePosY + 'px';
		verticallineHeight = 'calc(100% - ' + lastTimelineItemHeight + 'px)';
	} else {
		verticalline[0].style.top = '-' + verticallinePosY + 'px';
		verticallineHeight = 'calc(100% + 42px - 80px)';
	}


	if(verticalline[0].classList.contains('is--hidden')) {
		// anime.js Vertical Line Animation
		anime({
			targets: '.js-timeline__verticalline.is--hidden',
			height: verticallineHeight,
			duration: 2000,
			easing: 'easeInOutExpo',
			complete: function() {
				verticalline[0].classList.remove('is--hidden')
				// verticalline[0].style.height = 'calc(100% - ' + verticallinePosY + 'px - ' + lastTimelineItemHeight + 'px)';
			}
		});

		// anime.js timeline
		let animeTimeline = anime.timeline({
			easing: 'easeOutExpo',
			duration: 500
		});

		timelineAndMilestones.forEach(function(el){
			animeTimeline.add({
				targets: el,
				opacity: 1,
				complete: function() {
					if(breakpoint.value >= 800) {
						if(!el.classList.contains('js-timeline__milestone')) {
							const branch = el.getElementsByClassName('timeline__branch')[0];
							anime({
								targets: branch,
								width: '50%',
								duration: 200,
								easing: 'easeInOutExpo',
							});
							timeline.forEach(function(el){
								const buttonHeight = el.getElementsByClassName('timeline__title')[0].clientHeight;
								const branch = el.getElementsByClassName('timeline__branch')[0];
								branch.style.top = ((buttonHeight / 2) - 2) + 'px';
							});
						}
					}
				}
			});
		});
	}
}



function updateTimelineOnResize() {
	const verticallinePosY = (timeline[0].getElementsByClassName('timeline__title')[0].clientHeight / 2);
	const timelineBranch = document.querySelectorAll('.timeline__branch');

	if(breakpoint.value < 800) {
		timelineBranch.forEach(function(el){
			el.style.width = 0;
		});
		verticalline.forEach(function(el){
			el.style.top = '-' + verticallinePosY + 'px';
			el.style.height = 'calc(100% - 80px + ' + verticallinePosY + 'px)';
		});
	} else {
		timelineBranch.forEach(function(el){
			el.style.width = '50%';
		});
		verticalline.forEach(function(el){
			el.style.top = '-' + verticallinePosY + 'px';
			el.style.height = 'calc(100% - 80px)';
		});
	}

}


// Set Timeline Lines
if (timeline.length > 0) {
	if ('IntersectionObserver' in window) {
		function handleIntersection(entries) {
			entries.forEach(function(entry){
				if (entry.isIntersecting) {
					startTimeline();
				}
			});
		}
		const observer = new IntersectionObserver(handleIntersection, {
			threshold: 0.3,
		});
		observer.observe(timelinewrapper);
	} else {
		startTimeline();
	}
}

// Set Timeline Lines on Window Resize
window.addEventListener('resize', function(){
	if (timeline.length > 0) {
		setTimelineImagesOnResize();
		updateTimelineOnResize();
	}
});

class TrainAnimation {
	constructor(el) {
		this.s = {
			trainImage: ".js-train__train",
		};
		this.e = {
			section: el,
			train: el.querySelector(this.s.trainImage),
		};
		this.options = {
			animationSpeed: 0.0035, // 0.005 factor of viewport width to animated per second
			pauseAfterAnimation: 7000, // 4000 pause between animation cycles
		};
		this.state = {
			animationDistanceTotal: tabletInStorage()
				? window.innerHeight + this.e.train.offsetWidth
				: window.innerWidth + this.e.train.offsetWidth,
			animationDistance: 0,
			distancePerFrame: window.innerWidth * this.options.animationSpeed,
			transitioning: false, // true while train is transitioned from one edge to the other
			paused: true, // true while section not in view
			directionToLeft: true,
		};

		this.recalculate = this.recalculate.bind(this);
		this.throttledRecalculate = throttle(this.recalculate, 500, {
			trailing: true,
		});
		this.animateTrain = this.animateTrain.bind(this);

		this.init();

		return this;
	}

	init() {
		this.recalculate();
		this.attachObserver();
		this.animateTrain();
		window.addEventListener("resize", this.throttledRecalculate, false);
	}

	recalculate() {
		const width = tabletInStorage()
			? window.innerHeight
			: Math.max(window.innerWidth, 800);
		this.state.distancePerFrame = width * this.options.animationSpeed;
		this.state.animationDistanceTotal = tabletInStorage()
			? window.innerHeight + this.e.train.offsetWidth
			: window.innerWidth + this.e.train.offsetWidth;
	}

	/**
	 * Observe the section and pause the animation when not in view
	 */
	attachObserver() {
		if ("IntersectionObserver" in window) {
			const callback = (entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						if (this.state.paused) {
							this.state.paused = false;
							this.animateTrain();
						}
					} else {
						this.state.paused = true;
					}
				});
			};
			const observer = new IntersectionObserver(callback);
			observer.observe(this.e.section);
		}
	}

	/**
	 * Offset train translate each frame until total offset is larger than calculated max distance
	 */
	animateTrain() {
		if (this.state.paused) {
			return;
		}

		if (
			this.state.animationDistance > this.state.animationDistanceTotal ||
			this.state.animationDistance < 0
		) {
			this.state.transitioning = false;
			this.resetAnimation();
			return;
		}

		this.state.transitioning = true;
		if (this.state.directionToLeft) {
			this.state.animationDistance =
				this.state.animationDistance + this.state.distancePerFrame;
		} else {
			this.state.animationDistance =
				this.state.animationDistance - this.state.distancePerFrame;
		}
		let translation = Math.round(this.state.animationDistance * -1);
		this.e.train.style.transform = "translateX(" + translation + "px)";
		requestAnimationFrame(this.animateTrain);
	}

	/**
	 * Set train image to initial translate and restart animation after defined pause timeout
	 */
	resetAnimation() {
		//this.state.animationDistance = 0;
		//this.e.train.style.transition = 'none';
		//this.e.train.style.transform = 'translateX(0px)';
		this.state.directionToLeft = !this.state.directionToLeft;
		this.state.animationDistance = this.state.directionToLeft
			? 0
			: this.state.animationDistanceTotal;
		setTimeout(() => {
			if (!this.state.transitioning) {
				this.e.train.style.transition = "";
				this.animateTrain();
			}
		}, this.options.pauseAfterAnimation);
	}
}

function initTrainAnimations() {
	document.querySelectorAll(".js-train").forEach((trainSection) => {
		new TrainAnimation(trainSection);
	});
}

document.addEventListener("DOMContentLoaded", () => {
	if (document.body.id !== "mediaguide-homepage") {
		initTrainAnimations();
	}
});
